<template>
<div :class="pageStyles.content" style="padding-bottom: 0; padding-top: 0;">
    <h3>{{ t('pages.summary.header') }}</h3>
    <template v-if="['loading', 'init'].indexOf(fetchState.state) >= 0">
        <Loading :class="loadingStyles.loading"/>
    </template>
    <template v-else-if="fetchState.state === 'notok' || fetchState.state === 'error'">
        <ErrorView />
    </template>
    <template v-else-if="fetchState.state === 'ok' && fetchState.data.length === 0">
        <LaHighlightedMessage level="none">
            Keine Mitteilungen gefunden.
        </LaHighlightedMessage>
    </template>
</div>
<template v-if="fetchState.state === 'ok' && fetchState.data.length > 0">
    <template v-for="summaryContainer in fetchState.data" :key="summaryContainer.date">
        <div class="lan-list-date-container">
            <h4 class="lan-list-date">{{ formatDate(summaryContainer.date) }}</h4>
        </div>
        <div :class="gridStyles.container">
            <template
                v-for="summary in summaryContainer.summaries"
                :key="summary.type"
            >
                <div :class="gridStyles.seperator"></div>
                <NotificationSummary
                    @click="openDetails(summary.lastSeen, summary.type, summary.appId)"
                    :class="`${gridStyles.entry} summary`"
                    :message="summary.message"
                    :last-seen="summary.lastSeen"
                    :icon="summary.icon ?? '/notifications/system_avatars/system_broadcast.png'"
                    :count="summary.count"
                    :app-id="summary.appId"
                    :app-name="summary.appName"
                    :is-mobile="props.isMobile"
                />
            </template>
            <div v-if="summaryContainer.summaries.length % 2 === 1" :class="gridStyles.spacer"></div>
        </div>
    </template>
</template>
</template>
<script setup lang="ts">
import { fetchNotificationSummary, useFetch } from '@/utils/fetchplus';
import { onActivated, watch } from 'vue';
import NotificationSummary from '@/components/NotificationSummary.vue';
import { useRouter } from 'vue-router';
import { formatDate } from '@/utils/dateformatter';
import Loading from '@/components/Loading.vue';
import { useAppStore } from '../../store';
import ErrorView from '@/components/ErrorView.vue';
import { useI18n } from 'vue-i18n';
import { deGlobal } from '@/i18n/de';
import { enGlobal } from '@/i18n/en';

import loadingStyles from '@/styles/loading.module.css';
import pageStyles from '@/styles/page.module.css';
import gridStyles from '@/styles/grid.module.css';
import { storeToRefs } from 'pinia';
import LaHighlightedMessage from '@/components/LaHighlightedMessage.vue';


const props = defineProps<{
    appId?: string;
    isMobile: boolean;
}>();

const { state: fetchState, executeFetch } = useFetch(fetchNotificationSummary);
const router = useRouter();
const store = useAppStore();
const { selectedAppsForList, selectedTypesForList } = storeToRefs(store);
const { t } = useI18n({
    messages: {
        en: enGlobal,
        de: deGlobal
    }
});



watch([selectedAppsForList, selectedTypesForList], () => {
    executeIntialFetch();
});

onActivated(() => {
    executeIntialFetch();
});

function openDetails(instant: number, type: string, appId: string) {
    const beforeDate = new Date(instant);
    const afterDate = new Date(instant);
    afterDate.setHours(0, 0, 0, 0);
    beforeDate.setHours(23, 59, 59, 999);
    if (props.isMobile) {
        ///@ts-ignore
        window.nativePostMessage({
            type: 'navigation',
            page: 'pn-filterdetails',
            appId: appId,
            after: afterDate.getTime(),
            before: beforeDate.getTime(),
            pnType: type,
        });
    }
    else {
        router.push(`/filter/detail/${afterDate.getTime()}/${beforeDate.getTime()}/${type}/${appId}`);
    }
}

function executeIntialFetch() {
    if (props.appId) {
        executeFetch(store.selectedTypesForList, [props.appId]);
    }
    else {
        executeFetch(store.selectedTypesForList, store.selectedAppsForList);
    }
}
</script>
<style scoped>

.lan-list-date {
    margin: 0 auto;
    width: 150px;
    text-align: center;
    background-color: #ddd;
    color: #333;
    border-radius: 12px;
    font-weight: 600;
    font-size: 1rem;
    height: 1.2rem;
    border: 1px solid #bbb;
    line-height: 1.3rem;
    transform: translateY(-12px);
}

.lan-list-date-container{
    border-top: 1px solid #bbb;
    margin: 20px 0;
}

.summary {
    width: 100%;
}
</style>