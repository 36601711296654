<template>
<template v-for="notificationGroup in props.data" :key="notificationGroup.date">
    <div class="lan-list-date-container">
        <h4 class="lan-list-date">{{ formatDate(notificationGroup.date) }}</h4>
    </div>
    <div :class="gridStyles.container">
        <template
            v-for="notification in notificationGroup.notifications"
            :key="notification.id"
        >
            <div :class="gridStyles.seperator"></div>
            <Notification
                :class="`${gridStyles.entry} notification ${props.notificationClass ?? ''}`"
                :icon="notification.icon ?? '/notifications/system_avatars/system_broadcast.png'"
                :title="notification.title"
                :message="notification.message"
                :level="'info'"
                :target="notification.target"
                :create-date="notification.createDate"
                :app-id="notification.appId"
                :app-name="props.appData?.[notification.appId].name"
                :record-id="notification.recordId"
                :event-name="props.eventData?.[notification.type].name"
                :type="notification.type"
                :read="notification.read"
                :is-displayed-in-modal="props.isDisplayedInModal"
                :show-overlay="openedNotificationOverlay === notification.id"
                :is-mobile="props.isMobile"
                @open-more="handleOpenMoreNotification(notification.id)"
                @close-more="handleCloseMoreNotification(notification.id)"
            />
        </template>
        <div v-if="notificationGroup.notifications.length % 2 === 1" :class="gridStyles.spacer"></div>
    </div>
</template>
</template>
<script setup lang="ts">
import { TEvent, TResGetNotificationsLatest } from '@/models/responses';
import Notification from '@/components/Notification.vue';
import { formatDate } from '@/utils/dateformatter';

import gridStyles from '@/styles/grid.module.css';
import { TAppData } from '@/models/common';
import { ref } from 'vue';

const props = defineProps<{
    isDisplayedInModal?: boolean;
    data: TResGetNotificationsLatest,
    appData?: Record<string, TAppData>,
    eventData?: Record<string, TEvent>,
    notificationClass?: string,
    isMobile: boolean;
}>();
const emit = defineEmits<{
    (ev: 'openMore', id: string): void;
    (ev: 'closeMore', id: string): void;
}>();

const openedNotificationOverlay = ref<undefined | string>();


function handleOpenMoreNotification(id: string) {
    openedNotificationOverlay.value = id;
    emit('openMore', id);
}

/**
 * Close the notification more overlay, if it is closed by its own popper instance
 * @param id a notification id
 */
 function handleCloseMoreNotification(id: string) {
    if (openedNotificationOverlay.value === id) {
        openedNotificationOverlay.value = undefined;
    }
}
</script>
<style scoped>

.notification {
    width: 100%;
}

.lan-list-date {
    margin: 0 auto;
    width: 150px;
    text-align: center;
    background-color: #ddd;
    color: #333;
    border-radius: 12px;
    font-weight: 600;
    font-size: 1rem;
    height: 1.2rem;
    border: 1px solid #bbb;
    line-height: 1.3rem;
    transform: translateY(-12px);
}

.lan-list-date-container{
    border-top: 1px solid #bbb;
    margin: 20px 0;
}
</style>