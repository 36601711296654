<template>
    <div :class="pageStyles.content + ' content'">
        <h3 v-if="fetchState.state === 'ok'" class="header">
            <span>Einstellungen für LivingApp</span>
            <div class="appname">
                <div class="icon" :style="{ backgroundImage: `url('${imgProxy(fetchState.data.app.icon, { h: 64, w: 64 }, true)}')`}" ></div>
                <span>{{ fetchState.data.app.name }}</span>
            </div>
        </h3>
        <p>Wie sollen die Benachrichtigungen ausgeliefert werden?</p>
        <template v-if="displayErrorMessage">
            <ErrorView />
        </template>
        <template v-else-if="fetchState.state === 'ok'">
            <AppSetting
                v-for="setting in fetchState.data.events"
                v-bind="setting"
                :app="fetchState.data.app"
            />
        </template>
        <template v-else-if="['loading', 'init'].indexOf(fetchState.state) >= 0">
            <Loading :class="loadingStyles.loading"/>
        </template>
    </div>

</template>
<script setup lang="ts">
import AppSetting from '@/components/AppSetting.vue';
import { fetchAppSettings, useFetch } from '@/utils/fetchplus';
import { computed, onActivated, ref } from 'vue';
import Loading from '@/components/Loading.vue';
import ErrorView from '@/components/ErrorView.vue';

import loadingStyles from '@/styles/loading.module.css';
import pageStyles from '@/styles/page.module.css';
import { imgProxy } from '@/utils/imgproxy';

const props = defineProps<{ appId: string }>();

const { state: fetchState, executeFetch } = useFetch(fetchAppSettings);
const userAllowedPn = ref<'not-set' | 'denied' | 'allowed'>('not-set');
const sendPnDataProtectionError = ref(false);

const displayErrorMessage = computed(() => {
    return fetchState.value.state === 'notok' || fetchState.value.state === 'error' || sendPnDataProtectionError.value;
});
    
onActivated(async () => {
    await executeFetch(props.appId);
    if (fetchState.value.state === 'ok') {
        userAllowedPn.value = fetchState.value.data.userAllowedPn;
    }
});

</script>
<style scoped>

.header {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.header span {
    white-space: nowrap;
}

.header .appname span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.header .appname {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    text-overflow: ellipsis;
    max-width: 100%;
}

.content {
    padding-top: 0;
}

.icon {
    min-width: 45px;
    max-width: 45px;
    min-height: 45px;
    max-height: 45px;
    border: 2px solid #dedede;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>