<script setup lang="ts">
import CloseModalButton from '@/components/CloseModalButton.vue';
import BackButton from '@/components/BackButton.vue';
import pageStyles from '@/styles/page.module.css';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
<template>
<div :class="pageStyles.page">
    <div :class="pageStyles.bar">
        <BackButton />
        <h3></h3>
        <CloseModalButton />
    </div>
    <div :class="pageStyles.content" v-html="t('pages.settingsHelp.message')"></div>
</div>
</template>

<style scoped>
textarea {
    width: 100%;
    min-height: 200px;
    margin: 0;
    padding: 0.5rem 0.75rem;
    background-color: white;
    border: 2px solid rgb(226, 226, 226);

}

textarea:focus {
    border-color: #008bd2;
    outline: none;
}
</style>