// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Messaging, getMessaging, getToken, onMessage } from 'firebase/messaging';
import type { TReqPostPushTokensRegister, TReqPostPushTokensUnregister, TReqPostSettingsPush } from "./models/requests";
import type { TResGetSettingsUserPush } from "./models/responses";
import type { ClientSwMessage, PayloadNotification } from "./models/common";
import { createApp, readonly, ref } from "vue";
import { SupportedLocale } from "./i18n";
import { createI18n } from "vue-i18n";
import { PayloadDecrypted, decryptNotification } from "./utils/decrypt";
import { notificationMetadataStore } from "./utils/notificationmetastore";
import AppUserChange from './apps/AppPNUserChanged.vue';
import AppRequestPushPermission from './apps/AppPNRegistration.vue';
import { enGlobal } from "./i18n/en";
import { deGlobal } from "./i18n/de";
import { config } from "./config";
import { globalEventBus } from "./eventbus";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messageSenderId,
  appId: config.firebase.appId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging: Messaging | undefined = (() => {
    if (navigator.serviceWorker) {
        return getMessaging(app);
    }
    return undefined;
})();

export function useListenToNewNotificationsReceived() {
    const receivedCounter = ref(0);

    // does not cleanup, listener may exist longer than component is visible
    navigator.serviceWorker.addEventListener('message', (msgEvent: MessageEvent<ClientSwMessage>) => {
        if (msgEvent.data.type === 'receivedPN') {
            console.debug('increment receivedCounter, because foreground or background notification was received');
            receivedCounter.value += 1;
        }
    });


    async function reset() {
        receivedCounter.value = 0;
    }


    return {
        receivedCounter: readonly(receivedCounter),
        reset,
    };
}

if (messaging) {
    onMessage(messaging, async (payload) => {
        console.debug('process foreground notification');
        const registration = await navigator.serviceWorker.getRegistration('/');
        const payloadData = payload.data as PayloadNotification;
        let data: PayloadDecrypted;
        try {
            data = await decryptNotification(payloadData.data, payloadData.iv);
        }
        catch(e) {
            console.error(e);
            registration?.showNotification('Fehler bei Benachrichtung', { body: 'Es ist ein Fehler beim Entschlüsseln einer Nachricht entstanden, bitte melden Sie sich erneut an oder setzen Sie die Benachrichtungen im Browser zurück.' });
            return;
        }
        const opt = {
            body: data.message,
            icon: data.icon,
            data: {
                target: data.target
            },
        };
        registration?.showNotification(data.title, opt);
        // trigger updateing the badge counter
        globalEventBus.emit('setNotificationsAsRead', new Date());
        console.debug('send information to sw, which distributes the receivedPN event');
        const message: ClientSwMessage = { type: 'receivedPN' };
        (await navigator.serviceWorker.getRegistration('/'))?.active?.postMessage(message);
    
    });
}

/**
 * This function check if the user wants push notifications by checking his settings.
 * If he wants push notifications and has a browser which supports firebase cloud messaging
 * check if another user already has enabled push notifications by checking if an aes key is already
 * stored in the notification metadata store. If a user already has push notifications unregister
 * this browser.
 * Stop here if push notifications are not wanted.
 * Store the new aes key in the store, if push notifications are wanted.
 * Request Permission for enabling browser permissions via UI.
 * Register the serviceworker and the browser for push notifications
 * @param lang set the language for the vue apps
 * @param openFirstTime display the vue apps, even if the user did not enable push notifications yet or has them active
 */
export async function registerClientForPushNotifications(lang: SupportedLocale = 'de', openFirstTime: boolean = false) {
    if (!messaging) {
        return;
    }
    // fetch current aes key and check if a new user logged in.
    const storedAesKey = await notificationMetadataStore.getItem<string>('notification-aes-key');
    const aesKey = await fetchAesKey();
    let changedUser = storedAesKey && aesKey !== storedAesKey;
    // check if the browser is compatible for push ntoifications
    if ("Notificaton" !in window && 'serviceWorker' in navigator) {
        return;
    }
    // register the serviceworker
    console.debug('register service worker');
    const serviceWorker = await navigator.serviceWorker.register('/notification-sw.js', {});
    serviceWorker.addEventListener('updatefound', function() {
        console.debug('found an service worker update');
    });
    const interval = setInterval(async function registerClientIntervalCb() {
        if (serviceWorker.installing) {
            console.debug('serviceworker still installing');
        }
        else if (serviceWorker.waiting) {
            console.debug('serviceworker still waiting');
        }
        else if (!serviceWorker.active) {
            console.debug('no serviceworker active');
        }
        else if (serviceWorker.active !== null && serviceWorker.installing === null && serviceWorker.waiting === null) {
            const getTokenOptions = { vapidKey: 'BOuVMc9BeS9DVFZ2MOU__owrKkyTURiIIoDJZywMQv1JhyjZfUoAn3PEYFJyGCf-CADeBq24T76t_RvUgNvoD7w', serviceWorkerRegistration: serviceWorker };
            console.debug('finished registering serviceworker, register token now');
            clearInterval(interval);
            // handle case when user changed
            // ask if the user still wants to receive push notifications
            // and unregister the old device token
            if (changedUser) {
                console.debug('detected the user changed, unregister browser from firebase und delete aes key');
                await notificationMetadataStore.removeItem('notification-aes-key');
                sessionStorage.removeItem('tmp-no-push-notifications');
                localStorage.removeItem('no-push-notifications');
                const token = await getToken(messaging, getTokenOptions);
                await Promise.all([
                    unregisterToken(token),
                    // show dialog how the user can disable push notifications
                    new Promise((resolve) => {
                        const app = createApp(AppUserChange, {
                            resolveFn: resolve
                        });
                        const i18n = createI18n({
                            legacy: false,
                            locale: lang,
                            fallbackLocale: 'en',
                            messages: {
                                de: deGlobal,
                                en: enGlobal,
                            },
                        });
                        app.use(i18n);
                        const el = document.createElement('div');
                        document.body.append(el);
                        app.mount(el);
                    })
                ]);
            }
            // store aes key
            notificationMetadataStore.setItem('notification-aes-key', aesKey);
            // stop execution if user does not want to receive push notifications for this browser
            if (localStorage.getItem('no-push-notifications') !== null || sessionStorage.getItem('tmp-no-push-notifications') !== null) {
                return;
            }
            // check if the user wants push notifications
            const response = await fetch('/notifications_backend/settings/user/push');
            if (response.status !== 200) {
                return;
            }
            const body = (await response.json()) as TResGetSettingsUserPush;
            if (!openFirstTime && (!body.hasPushActive || !body.hasPushAllowed)) {
                return;
            }
            // ask for permission if no permission is granted
            if (Notification.permission === 'default' && sessionStorage.getItem('tmp-enabled-push-notifications') !== 'true') {
                await displayNotificationInformationModal(lang);
            }
            else if (Notification.permission === 'denied') {
                return;
            }
            if (localStorage.getItem('no-push-notifications') === 'true' ||  sessionStorage.getItem('tmp-no-push-notifications') === 'true') {
                return;
            }
            // register the token
            const token = await getToken(messaging, getTokenOptions);
            const responseTokenRegistration = await fetch('/notifications_backend/pushtokens/register', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                } satisfies TReqPostPushTokensRegister),
            });
            console.debug(`registering push token responded with ${responseTokenRegistration.status}`);
        }
    }, 100);
}

/**
 * registers the current client in the push notification service,
 * if the user wants it and it is supported
 */
export async function unregisterAllClientsFromPushNotifications() {
    const responseTokenUnRegistration = await fetch('/notifications_backend/pushtokens/unregister', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            unregisterAllTokens: true,
        } satisfies TReqPostPushTokensUnregister),
    });
    console.debug(`unregistered all tokens. responded with ${responseTokenUnRegistration.status}`);
}

/**
 * removes this token from the notification database
 */
export async function unregisterToken(token: string) {
    const responseTokenUnRegistration = await fetch('/notifications_backend/pushtokens/unregister', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            unregisterAllTokens: false,
            token,
        } satisfies TReqPostPushTokensUnregister),
    });
    console.debug(`unregistered token. responded with ${responseTokenUnRegistration.status}`);
}

/**
 * fetch the aes key for the currently logged in user
 */
export async function fetchAesKey(): Promise<string> {
    const maybeAesKeyFromStore = await notificationMetadataStore.getItem<string>('notification-aes-key');
    const response = await fetch('/notifications_backend/notifications/aes');
    if (response.status === 401) {
        throw new Error("Not logged in for the aes request");
    }
    else if (response.status !== 200) {
        throw new Error(`AES request responded with status ${response.status}`);
    }
    const body = await response.json();
    if (maybeAesKeyFromStore && maybeAesKeyFromStore !== body.message) {
        console.info('Detected an aes key for another user, disabeling push notifications');
    }
    return body.message;
}

export async function displayNotificationInformationModal(lang: SupportedLocale = 'de',) {
    const allowed = await new Promise(
        (resolve) => {
            const app = createApp(
                AppRequestPushPermission, {
                resolveFn: resolve
            });
            const i18n = createI18n({
                legacy: false,
                locale: lang,
                fallbackLocale: 'en',
                messages: {
                    de: deGlobal,
                    en: enGlobal,
                },
            });
            app.use(i18n);
            const el = document.createElement('div');
            document.body.append(el);
            app.mount(el);
        }
    );
    if (allowed === 'ok') {
        sessionStorage.removeItem('tmp-no-push-notifications');
        localStorage.removeItem('no-push-notifications');
        await Notification.requestPermission();
        // mark push notifications as allowed
        fetch('/notifications_backend/settings/user/push', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                allowPushNotifications: true,
            } as TReqPostSettingsPush),
        });
        sessionStorage.setItem('tmp-enabled-push-notifications', 'true');
    }
    else if (allowed === 'cancel') {
        localStorage.setItem('no-push-notifications', 'true');
        return;
    }
    else {
        sessionStorage.setItem('tmp-no-push-notifications', 'true');
        return;
    }
}