<template>
<div class="lan-card">
    <!-- Event Description -->
    <h3>{{ props.name[locale] }}</h3>
    <p>{{ props.description[locale] }}</p>

    <!-- Checkboxen for the setttings -->
    <div class="lan-settings-container">
        <div class="lan-item" @click="toggleAppSetting('inherit')">
                <Checkbox :checked="inherit"></Checkbox>
                {{ t('components.appSetting.inherit') }}
        </div>
        <div class="lan-item" @click="toggleAppSetting('showInCenter')">
            <Checkbox :checked="showInCenter || (inherit && userShowInCenter)" :greyed="inherit"></Checkbox>
            {{ t('components.appSetting.showInCenter') }}
        </div>
        <div class="lan-item" @click="toggleAppSetting('sendMail')">
            <Checkbox :checked="sendMail || (inherit && userSendMail)" :greyed="inherit"></Checkbox>
            {{ t('components.appSetting.sendMail') }}
        </div>
        <div class="lan-item" @click="toggleAppSetting('sendPushNotification')">
            <Checkbox :checked="sendPushNotification || (inherit && userSendPushNotification)" :greyed="inherit"></Checkbox>
            {{ t('components.appSetting.sendPushNotification') }}
        </div>
    </div>
</div>
</template>
<script setup lang="ts">
import { TAppData, TI18N } from '@/models/common';
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { SupportedLocale } from '@/i18n';
import { updateAppSettings, useFetch } from '@/utils/fetchplus';
import Checkbox from './Checkbox.vue';

const props = defineProps<{
    type: string;
    name: TI18N;
    description: TI18N;
    inherit: boolean;
    sendMail: boolean;
    sendPushNotification: boolean;
    showInCenter: boolean;
    userShowInCenter: boolean;
    userSendMail: boolean;
    userSendPushNotification: boolean;
    app: TAppData;
    displayPushNotificationOptions?: boolean;
}>();
const { inherit, showInCenter, sendMail, sendPushNotification } = toRefs(props);


const { executeFetch: executeFetchApp } = useFetch(updateAppSettings);

const { locale: tmpLocale, t } = useI18n();
const locale = computed(() => (tmpLocale.value as string).substring(0, 2) as SupportedLocale);

/** toggle the differnt settings. */
function toggleAppSetting(type: 'inherit' | 'showInCenter' | 'sendMail' | 'sendPushNotification') {
    if (type === 'inherit') {
        inherit.value = !inherit.value;
        showInCenter.value = false;
        sendMail.value = false;
        sendPushNotification.value = false;
    }
    else if (type === 'showInCenter') {
        inherit.value = false;
        showInCenter.value = !showInCenter.value;
    }
    else if (type === 'sendMail') {
        inherit.value = false;
        sendMail.value = !sendMail.value;
    }
    else if (type === 'sendPushNotification') {
        inherit.value = false;
        sendPushNotification.value = !sendPushNotification.value;
    }
    else {
        return;
    }
    executeFetchApp(props.app.appId, {
        type: props.type,
        inherit: inherit.value,
        showInCenter: showInCenter.value,
        sendMail: sendMail.value,
        sendPushNotification: sendPushNotification.value,
    });
}

</script>
<style scoped>
.lan-loading-indicator {
    position: absolute;
    top: 5px;
    right: 8px;
    height: 20px;
    width: 20px;
}
.lan-card {
    margin: 0 0 20px 0;
    padding: 10px;
    box-shadow: 0 1px 5px rgba(0,0,0,.2);
    position: relative;
}

.lan-card:last-of-type {
    margin: 0;
}

.lan-user-settings {
    color: #333;
}

.lan-item {
    width: 100%;
}

.lan-item:hover {
    background-color: #eee;
}
</style>