<template>
<div :class="{ 'lan-notification': true, 'lan-sub-hover': isMouseHoveringSubElement || showOverlay, 'lan-show-cursor': (props.target !== undefined) }" @click="openTarget">
    <div class="lan-icon-container">
        <div class="lan-icon" :style="{ backgroundImage: `url('${imgProxy(props.icon, { h: 64, w: 64 }, true)}')`}"></div>
    </div>
    <div class="lan-content lan-content-inner-container">
        <div class="lan-text">
            <h3 class="lan-headline">{{ props.title[locale] }}</h3>
            <AppNameLine :app="appNameDisplay" :record-id="recordId" :clickable="!props.isMobile" />
            <p class="lan-message">{{ props.message[locale] }}</p>
        </div>
        <!-- More Button, which enables to modify the settings very fast -->
        <div class="lan-buttons">
            <p class="lan-timestamp">{{ timestamp }}</p>
            <Popper
                :z-index="9999"
                :show="props.showOverlay ?? showOverlay"
                placement="bottom-end"
                class="lan-more-trigger-container"
                offsetDistance="0"
                @close:popper="emit('closeMore'); showOverlay = false"
            >
                <div @click="ev => handleClickOpenMore(ev, props.appId)" class="lan-more-container" @mouseenter="isMouseHoveringSubElement = true" @mouseleave="isMouseHoveringSubElement = false">
                    <i v-if="!read" :class="{ 'fa-solid': true, 'fa-circle': true, 'lan-unread': true }"></i>
                    <i :class="{ 'fa-solid': true, 'fa-ellipsis-vertical': true, 'lan-more': true, 'lan-more-open': showOverlay }"></i>
                </div>
                <template #content>
                    <div class="lan-popper-submenu">
                        <div v-if="fetchUserSetting.state === 'loading' || fetchAppSetting.state === 'loading'" class="lan-popper-submenu" style="height: 150px;" >
                            <Loading class="lan-loading"/>
                        </div>
                        <template v-else>
                            <div v-if="!read"
                                class="lan-submenu-item"
                                @click="(ev) => { ev.stopPropagation(); read = true; showOverlay = false;}"
                            >
                                <div class="lan-submenu-item-icon">
                                    <i class="fa-light fa-check"></i>
                                </div>
                                <div class="lan-submenu-item-text">
                                    {{ t('components.notification.submenu.markAsRead') }}
                                </div>
                            </div>
                            <div v-if="displayIgnoreInAppSettings && props.eventName && appName"
                                class="lan-submenu-item"
                                @click="(ev) => handleIgnoreAsAppSetting(ev, props.appId)"
                            >
                                <div class="lan-submenu-item-icon">
                                    <i class="fa-light fa-message-xmark"></i>
                                </div>
                                <div class="lan-submenu-item-text">
                                    {{ t('components.notification.submenu.ignoreAllForApp', { ev: props.eventName[locale], app: props.appName }) }}
                                </div>
                            </div>
                            <div v-if="displayIgnoreInUserSettings && props.eventName"
                                class="lan-submenu-item"
                                @click="handleIgnoreAsUserSetting"
                            >
                                <div class="lan-submenu-item-icon">
                                    <i class="fa-light fa-message-xmark"></i>
                                </div>
                                <div class="lan-submenu-item-text">
                                    {{ t('components.notification.submenu.ignoreAll', { ev: props.eventName[locale] }) }}
                                </div>
                            </div>
                            <div
                                class="lan-submenu-item"
                                @click="(ev) => openAppSettings(ev, props.appId)"
                            >
                                <div class="lan-submenu-item-icon">
                                    <i class="fa-light fa-gear"></i>
                                </div>
                                <div class="lan-submenu-item-text">
                                    <i18n-t keypath="components.notification.submenu.openAppSettings">
                                        <template #app>
                                            <span style="white-space: nowrap;">{{ props.appName ?? '...' }}</span>
                                        </template>
                                    </i18n-t>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </Popper>
        </div>
    </div>
</div>
</template>
<script setup lang="ts">
import { SupportedLocale } from '@/i18n';
import { TI18N } from '@/models/common';
import { TNotificationLevel } from '@/models/literaltypes';
import { imgProxy } from '@/utils/imgproxy';
import { computed, onActivated, onBeforeUnmount, onMounted, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import AppNameLine from './AppNameLine.vue';
import Popper from 'vue3-popper';
import { useRouter } from 'vue-router';
import { fetchAppSettings, fetchUserSettings, updateAppSettings, updateUserSettings, useFetch } from '@/utils/fetchplus';
import Loading from './Loading.vue';
import { useAppStore } from '@/store';
import { useEventListener } from '@vueuse/core';

const props = defineProps<{
    title: TI18N;
    message: TI18N;
    icon: string;
    target?: string;
    level: TNotificationLevel;
    fromUser?: string;
    createDate: number;
    appId: string;
    appName?: string;
    recordId?: string;
    eventName?: TI18N;
    type: string;
    read: boolean;
    showOverlay?: boolean;
    isDisplayedInModal?: boolean;
    isMobile: boolean;
}>();

const emit = defineEmits<{
    (ev: 'openMore'): void;
    (ev: 'closeMore'): void;
}>();

const { t } = useI18n();
const { state: fetchUserSetting, executeFetch: executeFetchUserSettings } = useFetch(fetchUserSettings);
const { state: fetchAppSetting, executeFetch: executeFetchAppSettings } = useFetch(fetchAppSettings);
const { executeFetch: executeUpdateUserSetting } = useFetch(updateUserSettings);
const { executeFetch: executeUpdateAppSetting } = useFetch(updateAppSettings);

const router = useRouter();
const store = useAppStore();
const { locale: tmpLocale } = useI18n();
const appNameDisplay = computed(() => {
    if (props.appId && props.appName) {
        return { appId: props.appId, name: props.appName }
    }
    return undefined;
});
const read = toRef(props.read);
const showOverlay = ref(false);
const isMouseHoveringSubElement = ref(false);

const locale = computed(() => tmpLocale.value.substring(0, 2) as SupportedLocale);
const timestamp = computed(() => {
    const date = new Date(props.createDate);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
});
const displayIgnoreInUserSettings = computed(() => {
    if (fetchUserSetting.value.state === 'ok') {
        const setting = fetchUserSetting.value.data.events.find(x => x.type === props.type);
        return setting && (setting.showInCenter || setting.sendMail || setting.sendPushNotification);
    }
    return false;
});

const displayIgnoreInAppSettings = computed(() => {
    if (fetchAppSetting.value.state === 'ok') {
        const appSetting = fetchAppSetting.value.data.events.find(x => x.type === props.type);
        if (appSetting?.inherit) {
            if (fetchUserSetting.value.state === 'ok') {
                const setting = fetchUserSetting.value.data.events.find(x => x.type === props.type);
                return setting && (setting.showInCenter || setting.sendMail || setting.sendPushNotification);
            }
        }
        return appSetting && (appSetting.showInCenter || appSetting.sendMail || appSetting.sendPushNotification);
    }
    return false;
});

// check if the popup should be closed if the user clicks away
useEventListener(document, 'click', (ev) => {
    if (!(props.showOverlay ?? showOverlay)) {
        return;
    }
    for (const el of ev.composedPath()) {
        if ((el as HTMLElement).classList?.contains('lan-popper-submenu')) {
            return;
        }
    }
    emit('closeMore');
});


// const eventListeners = [];
onMounted(() => {
    const popupFrameList = document.getElementsByClassName('lan-popup-frame');
    for (const popupFrame of popupFrameList) {
        popupFrame.addEventListener('click', (ev) => {
            if (!(props.showOverlay ?? showOverlay)) {
                return;
            }
            for (const el of ev.composedPath()) {
                if ((el as HTMLElement).classList?.contains('lan-popper-submenu')) {
                    return;
                }
            }
            emit('closeMore');
        });
    }
});
onBeforeUnmount(() => {

});

onActivated(() => {
    for (const f of document.getElementsByClassName('lan-popup-frame')) {
            useEventListener(f, 'click', (ev) => {
                if (!(props.showOverlay ?? showOverlay)) {
                    return;
                }
                for (const el of ev.composedPath()) {
                    if ((el as HTMLElement).classList?.contains('lan-popper-submenu')) {
                        return;
                    }
                }
                emit('closeMore');
            });
    }
});

/**
 * Prevents Event from bubbeling.
 * Emit event openMore, closeMore to vue events, if the element is not already openend
 * Triggers a fetch of the user and app settings.
 * @param ev event that should not bubble
 * @param appId appid for which the app settings should be updated
 */
function handleClickOpenMore(ev: Event, appId: string) {
    ev.stopPropagation();
    if (!props.showOverlay ?? !showOverlay.value) {
        emit('openMore');
        executeFetchUserSettings();
        executeFetchAppSettings(appId);
    }
    else {
        emit('closeMore')
    }
    showOverlay.value = !showOverlay.value;
}

function openTarget() {
    if (props.target) {
        if (props.isMobile) {
            ///@ts-ignore
            window.nativePostMessage({
                type: 'notification',
                pnType: props.type,
                title: props.title,
                message: props.message,
                appId: props.appId,
                recordId: props.recordId,
                icon: props.icon,
                target: props.target,
            })
        }
        else {
            window.location.href = props.target;
        }
    }
    else {
        window.alert("Diese Benachrichtigung ist nicht interaktiv.");
    }
}

function handleIgnoreAsUserSetting(ev: Event) {
    ev.stopPropagation();
    executeUpdateUserSetting({
        type: props.type,
        sendMail: false,
        sendPushNotification: false,
        showInCenter: false,
    });
    emit('closeMore');
    showOverlay.value = false;
}

function handleIgnoreAsAppSetting(ev: Event, appId: string) {
    ev.stopPropagation();
    executeUpdateAppSetting(appId, {
        type: props.type,
        inherit: false,
        sendMail: false,
        sendPushNotification: false,
        showInCenter: false,
    });
    emit('closeMore');
    showOverlay.value = false;
}

function openAppSettings(ev: Event, appId: string) {
    ev.stopPropagation();
    emit('closeMore');
    if (showOverlay.value) {
        showOverlay.value = false;
    }
    if (store.isPopperOpen) {
        store.isPopperOpen = false;
    }
    if (props.isMobile) {
        ///@ts-ignore
        window.nativePostMessage({
            type: 'navigation',
            page: 'pn-appsettings',
            appId: props.appId,
        });
    }
    else {
        router.push(`/settings/app/${appId}${props.isDisplayedInModal ? '?showBack=true' : ''}`);
    }
    if (!store.isModalOpen) {
        store.isModalOpen = true;
    }
}
</script>
<style scoped>
.lan-notification {
    display: flex;
    position: relative;
    gap: 10px;
    transition: background .3s ease-out;
}

.lan-show-cursor {
    cursor: pointer;
}

.lan-notification:hover {
    background-color: rgb(235, 238, 240);
}

.lan-sub-hover:hover {
    background-color: transparent;
}

.lan-headline {
    font-size: 1.25rem;
    font-weight: 400;
    color: rgb(51, 51, 51);
    margin: 0;
}

.lan-read {
    color: transparent;
    margin-left: 5px;
}


.lan-loading {
    height: 50px;
    width: 50px;
}

.lan-icon {
    width: 64px;
    height: 64px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: white;
}

.lan-content {
    flex: 1;
}

.lan-content-inner-container {
    display: flex;
}

.lan-content-inner-container .lan-text {
    flex: 1;
}

.lan-content-inner-container .lan-buttons {
    margin-right: 12px;
}

.lan-more-trigger-container {
    margin: 0;
    padding: 0;
    width: 100%;
}

.lan-title {
    margin: 0 20px 0 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.lan-message {
    margin: 0;
}


.lan-popper-submenu {
    background-color: white;
    box-shadow: 0 1px 6px rgba(0,0,0,.25);
    border-radius: 4px;
    margin: 0;
    text-align: left;
}

.lan-popper-submenu .lan-submenu-item {
    padding: .6875rem 1rem;
    max-width: 350px;
    margin: .1875rem 0;
    display: flex;
    gap: 10px;
    transition: background .3s ease-out;
}

.lan-popper-submenu .lan-submenu-item:first-of-type,.lan-submenu-item:last-of-type {
    margin:0;
}

.lan-popper-submenu .lan-submenu-item:hover {
    background-color: rgb(235, 238, 240);
}


.lan-popper-submenu .lan-submenu-item .disabled {
    color: #777;
}

.lan-submenu-item-icon {
    font-size: 1.5rem;
    align-self: center;
}

.lan-timestamp {
    margin-bottom: -3px;
    text-align: end;
}

.lan-more-container {
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 10px;
    align-items: center;
    width: 100%;
    transform: translateX(12px) translateY(-8px);
}

.lan-unread {
    color: rgb(198,56,53);
}

.lan-more {
    font-size: 1.5rem;
    color: #ccd2d6;
}

.lan-more-container:hover .lan-more {
    color: #b5bec4;
}


.lan-more-open {
    color: #b5bec4;
}

</style>