import SettingsHelpPageVue from "@/pages/SettingsHelpPage.vue";
import FilterDetailPageVue from "@/pages/browsershell/FilterDetails.vue";
import FilterHelpPageVue from "@/pages/FilterHelpPage.vue";
import FilterPage from "@/pages/browsershell/FilterPage.vue";
import { createMemoryHistory, createRouter } from "vue-router";
import SettingsPageVue from "@/pages/browsershell/SettingsPage.vue";

export function defineRouter(appId: string) {
    const router = createRouter({
        history: createMemoryHistory(),
        routes: [
            {
                path: '/filter',
                component: FilterPage,
                props: {
                    appId,
                },
            },
            {
                path: '/filter/help',
                component: FilterHelpPageVue,
            },
            {
                path: '/filter/detail/:after/:before/:type/:appId',
                component: FilterDetailPageVue,
                props: true,
            },
            {
                path: '/settings',
                component: SettingsPageVue,
                props: { appId }
            },
            {
                path: '/settings/help',
                component: SettingsHelpPageVue,
            },
            { path: '/settings/app/:appId', component: SettingsPageVue, props: true },
        ]
    });

    return router;
}