<template>
<div :class="pageStyles.content + ' content'">
    <Details>
        <template #summary="{}">
            <h3>Nutzereinstellungen</h3>
        </template>
        <template #default="displayDetails">
            <p>{{ t('pages.userSettings.description_2') }}</p>
            <p :class="displayDetails ? 'no-margin' : ''">{{ t('pages.userSettings.description_3') }}</p>
        </template>
    </Details>

    <template v-if="displayErrorMessage">
        <ErrorView />
    </template>
    <template v-else-if="fetchState.state === 'ok'">
        <UserSetting
            v-for="setting in fetchState.data.events"
            v-bind="setting"
            :display-push-notification-options="userAllowedPn === 'allowed'"
            :is-mobile="props.isMobile"
        />
    </template>
    <template v-else-if="['loading', 'init'].indexOf(fetchState.state) >= 0">
        <Loading :class="loadingStyles.loading"/>
    </template>
</div>
</template>
<script setup lang="ts">
import UserSetting from '@/components/UserSetting.vue';
import { useFetch, fetchUserSettings } from '@/utils/fetchplus';
import Loading from '@/components/Loading.vue';
import { computed, onActivated, ref } from 'vue';
import ErrorView from '@/components/ErrorView.vue';
import { useI18n } from 'vue-i18n';

import loadingStyles from '@/styles/loading.module.css';
import pageStyles from '@/styles/page.module.css';
import { deGlobal } from '@/i18n/de';
import { enGlobal } from '@/i18n/en';
import Details from '@/components/Details.vue';

const props = defineProps<{isMobile: boolean}>();

const { state: fetchState, executeFetch } = useFetch(fetchUserSettings);
const userAllowedPn = ref<'not-set' | 'denied' | 'allowed'>('not-set');
const sendPnDataProtectionError = ref(false);
const displayErrorMessage = computed(() => {
    return fetchState.value.state === 'notok' || fetchState.value.state === 'error' || sendPnDataProtectionError.value;
});

const { t } = useI18n({
    messages: {
        de: deGlobal,
        en: enGlobal
    }
});

// const showBack = router.currentRoute.value.query.showBack === 'true';

onActivated(async () => {
    await executeFetch();
    if (fetchState.value.state === 'ok') {
        userAllowedPn.value = fetchState.value.data.userAllowedPn;
    }
});

// function sendPushNotificationPermissionLocal(permission:  'allowed' | 'denied') {
//     sendPushNotificationPermission(permission, userAllowedPn, sendPnDataProtectionError);
// }
</script>
<style scoped>
.content {
    padding-top: 0;
}
.lla-warning {
    background: #fff7ee;
    border: 1px solid #ffd5a6;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 20px 0 0 0;
}
</style>