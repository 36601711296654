export const enGlobal = {
    apps: {
        bells: {
            header: 'Notifications',
            forApp: 'for',
            noNotifications: 'WIP'
        },
        pnRegistration: {
            header: 'WIP EN pn registration',
            message: `
                You want to be notified via push notification for at least one
                event in LivingApps. You must click on the "Allow push notifications" button
                and give permission in the browser afterwards. (Not up to date)
            `,
            warning: `
                You should allow push notifications only on devices, which only you
                use. As push notifications are sent to your device, you can
                receive them even if you are not logged in in LivingApps.
                (Not up to date)
            `
        },
        userChanged: {
            /** v-html */
            html: '<h3>WIP</h3>'
        }
    },
    pages: {
        list: {
            header: 'Notifications',
            pageDescription: 'On this page you can inspect the latest notifications',
            pageDescriptionFilter: 'You can apply filters to easily find relevant notifications.'
        },
        appFilter: {
            header: 'LivingApps filter',
            description: 'Select on this page for which LivingApps you want to see the notifications.',
            noAppsForSearch: 'no LivingApps found for input "{input}"',
            noApps: {
                header: 'no LivingApps available',
                description: 'you need at least one LivingApp to use this service'
            }
        },
        typeFilter: {
            header: 'Event filter',
            description: 'Select on this page for which events you want to see notifications.'
        },
        summary: {
            header: 'Summary',
        },
        summaryDetail: {
            header: 'Notifications',
        },
        userSettings: {
            header: 'User settings',
            description_1: 'You can select how you want to be informed about events. You can be informed via mail or push notification. If you want to change the settings for one LivingApp click on {link}. If you want to enable/disable push notifications for this browser click on {linkPn}',
            description_2: 'Your chosen settings are applied to all LivingApps you can access, except when for a given LivingApps you already explizit set other settings.',
            description_3: 'Your chosen settings are only valid for you. They do not apply for other users.',
        },
        appSettings: {
            headerLoadingStateNotOk: 'App settings',
            headerLoadingStateOk: '"{appName}" settings',
            description: 'You can select how you want to be informed about events in the LivingApp "{appName}". You can be informed via mail or push notification.',
        },
        appSettingsNav: {
            header: 'Select a LivingApp',
            description: 'Select an LivingApp for which you want to modify a setting.',
            searchNoAppFound: 'Did not find a LivingApp for "{input}".',
            noAppAvailable: {
                header: 'No LivingApp available',
                description: 'You need at least one LivingApp to use Notifications.'
            }
        },
        mailIntervalSelector: {
            header: 'E-mail settings',
            description_1: 'You selected that you want to be informed via e-mail.',
            description_2: 'You can now select how often you want to receive an e-mail.',
            infoAllSettings: 'Your setting will be applied to all settings where you selected e-mail.',
            select: {
                label: 'Receive a maximum of one e-mail',
                '15min': 'every 15 minutes',
                '30min': 'every 30 minutes',
                'hour': 'per hour',
                '2hours': 'every 2 hours',
                '6hours': 'every 6 hours',
                '12hours': 'every 12 hours',
                'day': 'per day',
                '3days': 'every 3 days',
                'week': 'peer week'
            }
        },
        summaryHelp: {
            header: 'Summary FAQ',
            /** passed to v-html */
            message: `
                <h3> WIP</h3>
        `
        },
        settingsHelp: {
            header: 'Settings FAQ',
            // v-html, supports html, do not include user content
            message: `
                <h3>WIP</h3>
            `
        },
        pushNotificationSettings: {
            header: 'push notification settings',
            browserDescriptions: {
                /** v-html */
                enableHtml: `
                    <h3>WIP enable</h3>
                `,
                /** v-html */
                disableHtml: `
                    <h3>WIP disable</h3>
                `,
            },
            clearBlockingPersistentTokens: {
                header: 'reenable push notifications',
                message: 'Click on the button to reenable push notifications.',
                button: 'reenable push notifications'
            }
        }
    },
    components: {
        filtericonBar: {
            header: 'Filters',
            description: 'Show notification for',
            descriptionSummary: 'Show summaries for',
        },
        noNotificationsView: {
            header: 'No notifications found',
            notificationsAvailableWithoutFilters_1:
                'We did not find any notifications with this filter configuration for you.',
            notificationsAvailableWithoutFilters_2:
                'If you remove the filters there are {n} notification available.',
            noNotificationsAvailable: `
                We did not find any notifications for you.
            `,
            directUserToSettings: 'Maybe you want to update your {settings} to receive more notifications',
        },
        noSummariesView: {
            header: 'No summaries found',
            withActiveFilters: 'You use filters, reset them to see if summaries are available.',
            noSummaries: 'Maybe you want to update your {settings} to receive more notifications',
        },
        filtersNotValid: {
            header: 'Filters not valid',
            noAppSelected: 'You have to select at least one LivingApp for using the LivingApp Filter.',
            noTypeSelected: 'You have to select at least one event for using the event Filter.',
            invalidTimespan: 'The given timespan is invalid. please select another one.'
        },
        error: {
            header: 'An error happend',
            description: 'Please reload the page. If the error still occurs, contact the support.'
        },
        dateSpanFilter: {
            description: `Currently the notifications between {from} and {to} are visible.`
        },
        appSetting: {
            inherit: 'Inherit the user settings',
            /** also used by appSetting */
            showInCenter: 'show notifications on platform',
            /** also used by appSetting */
            sendMail: 'send notifications via email',
            /** also used by appSetting */
            sendPushNotification: 'send via push notifications'
        },
        userSetting: {
            mailDescription: 'You want to receive a mail. You can control how in the {mailSettings} many e-mails you want to receive.',
            overwrittenAppsExists: 'For this event there already exist explizit set settings in {count} LivingApps.',
            overwrittenAppSettings: {
                editing: 'You can edit the settings for this app {link}.',
                inherit: 'Inherits settings from the user settings.',
                link: 'here'
            }
        },
        notificationsSummary: {
            lastSeen: 'Last seen:',
            numberOfNotifications: 'Number of notifications:',
        },
        searchbar: 'Search',
        dataProtectionPn: {
            notSetText: `To use push notifications you must allow Google and Apple to process data.

            The data may be processed by them outside of europe.

            For more information click {link}`,
            linkToPage: 'here',
            denyButton: 'deny push notifications',
            allowButton: 'allow push notifications',
            deniedText: `
                Push notifications are disabled. More information to the privacy and you can modify your setting {link}.
            `,
            allowedText: 'Push notifications are allowed. More information to the privacy and you can modify your setting {link}'
        },
        details: {
            more: 'display more',
            less: 'display less',
        },
        privacyHint: {
            header: 'data protection hint',
            message: 'WIP'
        },
        allowPn: {
            // v-html, do not insert any user content
            message: 'WIP',
            button: 'WIP'
        },
        notification: {
            submenu: {
                markAsRead: 'mark as read',
                ignoreAll: 'ignore all new "{ev}" events in the future',
                ignoreAllForApp: 'ignore all new "{ev}" events in app "{app}"',
                openAppSettings: 'open LivingApp settings for app {app}'
            }
        }
    },
    buttons: {
        resetAppFilter: 'reset LivingApp filter',
        resetTypeFilter: 'reset event filter',
        resetDateFilter: 'reset date filter',
        resetSearch: 'reset search',
        reloadPage: 'reload page',
        loadMoreNotifications: 'load more',
        selectAll: 'select all',
        deselectAll: 'deselect all',
        applyFilter: 'Filter anwenden',
        appFilter: 'no LivingApp | one LivingApp | {n} LivingApps',
        typeFilter: 'no event | one event | {n} events',
        dateFilter: 'from {from} to {to}',
        allAppsSelected: 'all LivingApps',
        allTypesSelected: 'all events',
        showOverwrittenApps: 'show apps',
        hideOverwrittenApps: 'hide apps,',
        openInTab: 'open in new tab',
        pnRegisterOk: 'allow push notifications',
        pnRegisterCancel: 'cancel',
    },
    nav: {
        settings: 'Settings',
        summary: 'Summary',
        help: 'Help',
        appSettings: 'LivingApp settings',
        mailSettings: 'Mail settings',
        pnSettings: 'Push Notification settings',
        privacyHtm: 'privacy',
    }
};