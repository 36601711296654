<template>
<LaHighlightedMessage level="none">
    <h3>{{ t('components.filtersNotValid.header') }}</h3>
    <template v-if="store.selectedAppsForList?.length === 0">
        <p>{{ t('components.filtersNotValid.noAppSelected') }}</p>
        <LaButton color="primary" size="large" @click="resetAppFilter">{{ t('buttons.resetAppFilter') }}</LaButton>
    </template>
    <template v-if="store.selectedTypesForList?.length === 0">
        <p>{{ t('components.filtersNotValid.noTypeSelected') }}</p>
        <LaButton color="primary" size="large" @click="resetTypeFilter">{{ t('buttons.resetTypeFilter') }}</LaButton>
    </template>
    <template v-if="store.selectedDateSpan?.valid === false">
        <p>{{ t('components.filtersNotValid.invalidTimespan') }}</p>
        <LaButton color="primary" size="large" @click="resetDateFilter">{{ t('buttons.resetDateFilter') }}</LaButton>
    </template>
</LaHighlightedMessage>
</template>
<script setup lang="ts">
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { useAppStore } from '@/store';
import { useI18n } from 'vue-i18n';
import LaHighlightedMessage from './LaHighlightedMessage.vue';

const emit = defineEmits<{
    (ev: 'reset'): void,
}>();

const { t } = useI18n();
const store = useAppStore();

function resetAppFilter() {
    store.selectedAppsForList = undefined;
    emit('reset');
}

function resetTypeFilter() {
    store.selectedTypesForList = undefined;
    emit('reset');
}

function resetDateFilter() {
    store.selectedDateSpan = undefined;
    emit('reset');
}
</script>
<style>

</style>