<script setup lang="ts">

</script>
<template>
<div class="la-badge">
    <slot></slot>
</div>
</template>
<style scoped>
.la-badge {
    border-radius: 10px;
    background-color: rgb(198,56,53);
    padding: 3px 7px;
    min-width: 10px;
    height: 18px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    line-height: 14px;
    color: white;
}
</style>