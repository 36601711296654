<template>
<div :class="pageStyles.page">
    <div :class="pageStyles.bar">
        <BackButton />
        <h3></h3>
        <div :class="navigationStyles.lgScreen">
            <RouterLink to="/filter/help">
                <i class="fa-light fa-question"></i> {{ t('nav.help') }}
            </RouterLink>
        </div>
        <CloseModalButton />
    </div>
    <div :class="pageStyles.content" style="padding-bottom: 0;">
        <div :class="navigationStyles.smScreen">
        <LaButton
                size="expand"
                color="primary"
                :class="navigationStyles.buttonWithIcon"
                @click="router.push('/filter/help')"
            >
                <i class="fa-light fa-question"></i> {{ t('nav.help') }}
            </LaButton>
        </div>
    </div>
    <FilterDetails v-bind="props" :is-mobile="false"></FilterDetails>
</div>
</template>
<script setup lang="ts">
import CloseModalButton from '@/components/CloseModalButton.vue';
import BackButton from '@/components/BackButton.vue';
import { useI18n } from 'vue-i18n';
import { enGlobal } from '@/i18n/en';
import { deGlobal } from '@/i18n/de';
import FilterDetails from '../content/FilterDetails.vue';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { useRouter } from 'vue-router';

import pageStyles from '@/styles/page.module.css';
import navigationStyles from '@/styles/navigation.module.css';


const props = defineProps<{
    after: number;
    before: number;
    type: string;
    appId?: string;
}>();

const router = useRouter();

const { t } = useI18n({
    messages: {
        en: enGlobal,
        de: deGlobal,
    }
});

</script>