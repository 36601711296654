<template>
    <h3>{{ t('components.error.header') }}</h3>
    <p>
        {{ t('components.error.description') }}
    </p>
    <p>
        <LaButton
            @click="reload"
            color="red"
        >{{ t('buttons.reloadPage') }}</LaButton>
    </p>
</template>
<script setup lang="ts">
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

function reload() {
    window.location.reload();
}

</script>
<style scoped>

</style>