<template>
<div class="searchbar-container">
    <input
        class="searchbar-input"
        :placeholder="t('components.searchbar')"
        :value="value"
        @input="updateValue"
    />
    <i
        :class="`fa-solid ${value ? 'fa-x searchbar-active' : 'fa-magnifying-glass'} searchbar-icon`"
        @click="resetValue"
    ></i>
</div>
</template>
<script setup lang="ts">
import { toRef } from 'vue';
import { useI18n } from 'vue-i18n';


const props = defineProps<{
    value?: string;
}>();

const value = toRef(props, 'value');

const emit = defineEmits<{
    (ev: 'input', value: string): void;
}>();

const { t } = useI18n();

function updateValue(ev: Event) {
    const el = ev.target as HTMLInputElement;
    const v = el.value;
    value.value = v;
    emit('input', v);
}

function resetValue() {
    value.value = '';
    emit('input', '');
}

</script>
<style scoped>

.searchbar-container  {
    position: relative;
    display: flex;
    border: 2px solid #ccd2d6;
    border-radius: 5px;
}

.searchbar-input {
    flex: 1;
    padding: 0.5rem 0.75rem;
    width: 100%;
    color: #333;
    font-size: 1rem;
    outline: none;
    user-select: text;
    margin: 0;
    border: none;
}

.searchbar-icon {
    align-self: center;
    color: #999;
    padding: 3px;
    font-size: 1.25rem;
    padding: 0 10px;
}

.searchbar-active {
    color: #168dd0;
    cursor: pointer;
}

</style>