<template>
<div :class="pageStyles.content + ' content'">
    <h3>{{ t('pages.mailIntervalSelector.header') }}</h3>

    <p>{{ t('pages.mailIntervalSelector.description_1') }}</p>
    <p>{{ t('pages.mailIntervalSelector.description_2') }}</p>
    <LaHighlightedMessage level="none">
        {{ t('pages.mailIntervalSelector.infoAllSettings') }}
    </LaHighlightedMessage>

    <template v-if="['loading', 'init'].indexOf(stateInit.state) >= 0">
        <Loading :class="loadingStyles.loading" />
    </template>
    <template></template>
    <div style="margin-bottom: 20px;">
        <select class="ll-notification-select" @change="handleChangeType">
            <option :selected="selectedType === 'interval'" value="interval">Interval</option>
            <option :selected="selectedType === 'time'" value="time">Zeitpunkte</option>
        </select>
    </div>
    <div v-if="selectedType === 'interval'">
        <select class="ll-notification-select" @change="handleUpdateSelect" :value="selectedInterval">
            <option :selected="selectedInterval === 15" value="15">{{ t('pages.mailIntervalSelector.select.15min') }}</option>
            <option :selected="selectedInterval === 30" value="30">{{ t('pages.mailIntervalSelector.select.30min') }}</option>
            <option :selected="selectedInterval === 60" value="60">{{ t('pages.mailIntervalSelector.select.hour') }}</option>
            <option :selected="selectedInterval === 120" value="120">{{ t('pages.mailIntervalSelector.select.2hours') }}</option>
            <option :selected="selectedInterval === 360" value="360">{{ t('pages.mailIntervalSelector.select.6hours') }}</option>
            <option :selected="selectedInterval === 720" value="720">{{ t('pages.mailIntervalSelector.select.12hours') }}</option>
            <option :selected="selectedInterval === 1440" value="1440">{{ t('pages.mailIntervalSelector.select.day') }}</option>
            <option :selected="selectedInterval === 4320" value="4320">{{ t('pages.mailIntervalSelector.select.3days') }}</option>
            <option :selected="selectedInterval === 10080" value="10080">{{ t('pages.mailIntervalSelector.select.week') }}</option>
        </select>
    </div>
    <div v-else>
        <div v-for="(time, i) in selectedTimes" :key="i" :class="i === selectedTimes.length - 1 ? 'time-container last' : 'time-container'">
            jeden
            <div class="day-container">
                <LaButton
                    :color="time.days.includes('MONDAY') ? 'primary' : 'grey2'"
                    class="no-margin-button"
                    @click="toggleDay(i, 'MONDAY')"
                >Mo</LaButton>
                <LaButton
                    :color="time.days.includes('TUESDAY') ? 'primary' : 'grey2'"
                    class="no-margin-button"
                    @click="toggleDay(i, 'TUESDAY')"
                    
                >Di</LaButton>
                <LaButton
                    :color="time.days.includes('WEDNESDAY') ? 'primary' : 'grey2'"
                    class="no-margin-button"
                    @click="toggleDay(i, 'WEDNESDAY')"
                >Mi</LaButton>
                <LaButton
                    :color="time.days.includes('THURSDAY') ? 'primary' : 'grey2'"
                    class="no-margin-button"
                    @click="toggleDay(i, 'THURSDAY')"
                >Do</LaButton>
                <LaButton
                    :color="time.days.includes('FRIDAY') ? 'primary' : 'grey2'"
                    class="no-margin-button"
                    @click="toggleDay(i, 'FRIDAY')"
                >Fr</LaButton>
                <LaButton
                    :color="time.days.includes('SATURDAY') ? 'primary' : 'grey2'"
                    class="no-margin-button"
                    @click="toggleDay(i, 'SATURDAY')"
                >Sa</LaButton>
                <LaButton
                    :color="time.days.includes('SUNDAY') ? 'primary' : 'grey2'"
                    class="no-margin-button"
                    @click="toggleDay(i, 'SUNDAY')"
                >So</LaButton>
            </div>
            um
            <div class="time-input-container">
                <input type="time" @change="ev => changeTime(ev, i)" :value="`${String(time.time[0]).padStart(2, '0')}:${String(time.time[1]).padStart(2, '0')}`" />
            </div>
            <div style="flex: 1;" data-type="spacer"></div>
            <div class="delete-container">
                <LaButton color="red" @click="deleteTime(i)" class="no-margin-button">
                    <i class="fa-light fa-trash"></i>
                    Löschen
                </LaButton>
            </div>
        </div>
        <div class="button-container">
            <LaButton color="primary" @click="addTime">
                Zeitpunkte hinzufügen
            </LaButton>
        </div>
    </div>
</div>

</template>

<script setup lang="ts">
/// the user should set on this page in which maximal interval
/// he should receive notifications via mail
import { useFetch, getMailSetting, updateMailSetting } from '@/utils/fetchplus';
import { useI18n } from 'vue-i18n';
import LaHighlightedMessage from '@/components/LaHighlightedMessage.vue';
import { nextTick, onMounted, ref, watch } from 'vue';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { TDay } from '@/models/common';
import Loading from '@/components/Loading.vue';
import pageStyles from '@/styles/page.module.css';

import loadingStyles from '@/styles/loading.module.css';

type TSelectedTime = {
    time: [number, number],
    days: TDay[]
};

const { t } = useI18n();
const { executeFetch } = useFetch(updateMailSetting);
const { executeFetch: initMailSetting, state: stateInit } = useFetch(getMailSetting);

const selectedInterval = ref(15);
const selectedTimes = ref<TSelectedTime[]>([{
    time: [10, 0],
    days: ['MONDAY', 'WEDNESDAY', 'FRIDAY'],
}]);
const showTimeError = ref(false);
const selectedType = ref<'interval' | 'time'>('interval');

const tab_interval = ref<HTMLElement | undefined>();
const tab_times = ref<HTMLElement | undefined>();

onMounted(async () => {
    await initMailSetting();
    if (stateInit.value.state === 'ok') {
        if ('interval' in stateInit.value.data.setting) {
            selectedType.value = 'interval';
            selectedInterval.value = stateInit.value.data.setting.interval;
        }
        else if ('times' in stateInit.value.data.setting) {
            selectedType.value = 'time';
            selectedTimes.value = stateInit.value.data.setting.times.map(v => ({
                days: v.days,
                time: [Math.floor(v.time / 60), v.time % 60],
            }));
        }
        nextTick(() => {
            console.info(selectedType.value, tab_interval, tab_times);
            if (selectedType.value === 'interval')
                tab_interval.value?.click();
            else
                tab_times.value?.click();
        });
    }
});

watch([selectedType, selectedTimes, selectedInterval], () => {
    console.info('update mail settings');
    if (selectedType.value === 'interval') {
        executeFetch({ type: 'interval', interval: selectedInterval.value });
    }
    else {
        executeFetch({ type: 'times', times: selectedTimes.value.map(v => ({ days: v.days, time: v.time[0] * 60 + v.time[1]}))});
    }
}, {
    immediate: false,
});

async function handleUpdateSelect(ev: Event) {
    const target = ev.target as HTMLSelectElement;
    const valueAsString = target.value;
    const value = Number(valueAsString);
    if (Number.isNaN(value) || !Number.isFinite(value))
        return;
    selectedInterval.value = value;
}

function handleChangeType(ev: Event) {
    const target = ev.target as HTMLSelectElement;
    ///@ts-expect-error
    selectedType.value =  target.value;
}

function changeTime(ev: Event, index: number) {
    const v = (ev.target as HTMLInputElement).value;
    const vSplit = v.split(':');
    const hours = Number(vSplit[0]);
    const minutes = Number(vSplit[1]);
    if (hours < 0 || hours > 24 || minutes < 0 || minutes > 60) {
        showTimeError.value = true;
    }
    selectedTimes.value[index].time = [hours, minutes];
    selectedTimes.value = [...selectedTimes.value];
}

function addTime() {
    selectedTimes.value.push({
        time: [10, 0],
        days: ['MONDAY', 'WEDNESDAY', 'FRIDAY'],
    });
}

function deleteTime(index: number) {
    selectedTimes.value.splice(index, 1);
}

function toggleDay(index: number, day: TDay) {
    let  time = selectedTimes.value[index];
    if (time) {
        if (time.days.includes(day)) {
            time.days = time.days.filter((v) => v !== day);
        }
        else {
            time.days.push(day);
        }
        selectedTimes.value = [...selectedTimes.value];
    }
}

</script>

<style scoped>

.content {
    padding-top: 0;
}

.no-margin-button {
    margin: 0;
}

.loading {
    max-height: 16px;
    max-width: 16px;
}

.time-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-bottom: 1px solid #a1a1a1;
    align-items: start;
    padding: 10px 0;
    gap: 10px;
}

.time-container.last {
    border-bottom: none;
}

.day-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-shrink: 200px;
}

.delete-container {
    width: fit-content;
}

.button-container {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}

.ll-notification-select {
    font-size: 1rem;
    line-height: normal;
    color: #333;
    width: 100%;
    padding: 0.5em 0.75em;
    background-color: #fff;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjEyIiB3aWR0aD0iMjQiIGhlaWdodD0iMyIgdmlld0JveD0iMCAwIDYgMyI+PHBhdGggZD0iTTUuOTkyIDBsLTMgMy0zLTN6Ii8+PC9zdmc+");
    background-position: 100%;
    background-repeat: no-repeat;
    border: 2px solid #e2e2e2;
    border-radius: 0;
    appearance: none;
}

.ll-notification-select:focus {
    border-color: #008bd2;
    outline: 0;
}

@media screen and (min-width: 992px) {
    .time-container {
        flex-direction: row;
        align-items: center;
    }
}
</style>