import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useAppStore = defineStore('bell-all', () => {
    const selectedAppsForList = ref<string[] | undefined>(undefined);
    const selectedTypesForList = ref<string[] | undefined>(undefined);
    const selectedDateSpan = ref<{from: Date, until: Date, valid: boolean} | undefined>(undefined);
    const isModalOpen = ref(false);
    const isPopperOpen = ref(false);
    return {
        selectedAppsForList,
        selectedTypesForList,
        isModalOpen,
        selectedDateSpan,
        isPopperOpen,
    };
});