import { TAppData } from "@/models/common";
import { THttpMailSetting, TReqPostSettingsApp, TReqPostSettingsUser, TReqPostSettingsUserMail } from "@/models/requests";
import { TEvent, TResGetNotificationsLatest, TResGetNotificationsSummary, TResGetOnboarding, TResGetSettingsApp, TResGetSettingsTypes, TResGetSettingsUser, TResGetSettingsUserMail, TResGetUserLastCheckedCenterCount } from "@/models/responses";
import { ref, type Ref } from "vue";
import { formatDate } from "./dateformatter";

export type InitState = {
    state: 'init';
};

export type LoadingState = {
    state: 'loading';
};

export type OkResponseState<T> = {
    state: 'ok';
    data: T;
};

export type NotOkResponseState<T> = {
    state: 'notok';
    data: T;
};

export type ErrorState = {
    state: 'error';
    error: Error;
};

export type State<T, S> = InitState | LoadingState | OkResponseState<T> | NotOkResponseState<S> | ErrorState;

export type DefaultNotOk = { status: number };

export function useFetch<F extends unknown[], T, S>(queryFn: (...args: [...F]) => Promise<OkResponseState<T> | NotOkResponseState<S>>) {
    const state = ref<State<T, S>>({state: 'init'}) as Ref<State<T,S>>;

    async function executeFetch(...args: F) {
        state.value = { state: 'loading' };
        try {
            state.value = await queryFn(...args);
        }
        catch(e) {
            if (e instanceof Error) {
                state.value = { state: 'error', error: e };
            }
            else {
                console.error(e);
                state.value = {state: 'error', error: new Error("error is not of type Error, check the console") };
            }
        }
    }

    return {
        state,
        executeFetch,
    }
}



export type LoadingPaginationState<T> = {
    state: 'loading-pagination';
    data: T;
};

export type PaginationState<T, S> = State<T, S> | LoadingPaginationState<T>;

export function usePaginationFetch<F extends unknown[], G extends unknown[], T, S>(
    queryFn: (...args: [...F]) => Promise<OkResponseState<T> | NotOkResponseState<S>>,
    paginationFn: (oldData: T, ...args: [...G]) => Promise<OkResponseState<T> | NotOkResponseState<S>>
) {
    const state = ref<PaginationState<T, S>>({state: 'init'}) as Ref<PaginationState<T,S>>;

    async function executeFetch(...args: F) {
        state.value = { state: 'loading' };
        try {
            state.value = await queryFn(...args);
        }
        catch(e) {
            if (e instanceof Error) {
                state.value = { state: 'error', error: e };
            }
            else {
                console.error(e);
                state.value = {state: 'error', error: new Error("error is not of type Error, check the console") };
            }
        }
    }

    async function executePaginationFetch(...args: G) {
        if (state.value.state === 'ok') {
            const data = state.value.data;
            state.value = { state: 'loading-pagination', data, };
            try {
                state.value = await paginationFn(data, ...args);
            }
            catch(e) {
                if (e instanceof Error) {
                    state.value = { state: 'error', error: e };
                }
                else {
                    console.error(e);
                    state.value = {state: 'error', error: new Error("error is not of type Error, check the console") };
                }
            }
        }
        else {
            console.warn('pagination is only allowed if the state is ok');
            state.value = { state: 'error', error: new Error('pagination is only allowed if state is ok') };
        }
    }

    return {
        state,
        executeFetch,
        executePaginationFetch,
    }
}

export async function fetchNotificationLatest(
    after: number | undefined,
    before: number | undefined,
    types: string[] | undefined,
    apps: string[] | undefined,
    limit: number = 500,
    unread?: boolean,
): Promise<OkResponseState<TResGetNotificationsLatest> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL('/notifications_backend/notifications', `${window.location.protocol}//${window.location.hostname}`);
    if ((apps && apps.length) === 0)
        throw new Error("if apps is provided, the array requires to contain at least one element");
    if ((types && types.length) === 0)
        throw new Error("if types is provided, the array requires to contain at least one element");
    if (before)
        url.searchParams.append('before', String(before));
    if (after)
        url.searchParams.append('after', String(after));
    if (types)
        url.searchParams.append('types', types.join(','));
    if (apps)
        url.searchParams.append('apps', apps.join(','));
    if (unread !== undefined)
        url.searchParams.append('unread', String(unread));
    url.searchParams.append('tzoffset', String(new Date().getTimezoneOffset()));
    url.searchParams.append('limit', String(limit));
    url.searchParams.append('showInCenter', String(true));
    const response = await fetch(url);
    if (response.status === 200) {
        const body = await response.json() as TResGetNotificationsLatest;
        return {
            state: 'ok',
            data: body,
        }
    }
    else {
        return {
            state: 'notok',
            data: { status: response.status },
        }
    }
}

export async function pageinateNotificationLatest(
    oldData: TResGetNotificationsLatest,
    after: number | undefined,
    before: number | undefined,
    types: string[] | undefined,
    apps: string[] | undefined,
    limit: number = 500
): Promise<OkResponseState<TResGetNotificationsLatest> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL('/notifications_backend/notifications', `${window.location.protocol}//${window.location.hostname}`);
    if ((apps && apps.length) === 0)
        throw new Error("if apps is provided, the array requires to contain at least one element");
    if ((types && types.length) === 0)
        throw new Error("if types is provided, the array requires to contain at least one element");
    if (before)
        url.searchParams.append('before', String(before));
    if (after)
        url.searchParams.append('after', String(after));
    if (types)
        url.searchParams.append('types', types.join(','));
    if (apps)
        url.searchParams.append('apps', apps.join(','));
    url.searchParams.append('tzoffset', String(new Date().getTimezoneOffset()));
    url.searchParams.append('limit', String(limit));
    url.searchParams.append('showInCenter', String(true));
    const response = await fetch(url);
    if (response.status === 200) {
        const body = await response.json() as TResGetNotificationsLatest;
        if (body.length > 0 && formatDate(oldData.at(-1)!.date) === formatDate(body.at(0)!.date)) {
            return {
                state: 'ok',
                data: [
                    ...oldData.slice(0, oldData.length-1),
                    {
                        date: oldData.at(-1)!.date,
                        notifications: [...oldData.at(-1)!.notifications, ...body.at(0)!.notifications]
                    },
                    ...body.slice(1),
                ],
            }
        }
        else {
            return {
                state: 'ok',
                data: oldData.concat(body),
            }
        }
    }
    else {
        return {
            state: 'notok',
            data: { status: response.status },
        }
    }
}

export async function fetchNotificationSummary(
    types: string[] | undefined,
    apps: string[] | undefined,
): Promise<OkResponseState<TResGetNotificationsSummary> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL('/notifications_backend/notifications/summary', `${window.location.protocol}//${window.location.hostname}`);
    if ((apps && apps.length) === 0)
    throw new Error("if apps is provided, the array requires to contain at least one element");
    if ((types && types.length) === 0)
        throw new Error("if types is provided, the array requires to contain at least one element");

    if (types)
        url.searchParams.append('types', types.join(','));
    if (apps)
        url.searchParams.append('apps', apps.join(','));
    url.searchParams.append('tzoffset', String(new Date().getTimezoneOffset()));
    url.searchParams.append('showInCenter', String(true));
    const response = await fetch(url);
    if (response.status === 200) {
        const body = await response.json() as TResGetNotificationsSummary;
        return {
            state: 'ok',
            data: body,
        }
    }
    else {
        return {
            state: 'notok',
            data: { status: response.status },
        }
    }
}


export async function fetchUserSettings(): Promise<OkResponseState<TResGetSettingsUser> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL('/notifications_backend/settings/user', `${window.location.protocol}//${window.location.hostname}`);
    const response = await fetch(url);
    if (response.status === 200) {
        const body = await response.json() as TResGetSettingsUser;
        return {
            state: 'ok',
            data: body,
        }
    }
    else {
        return {
            state: 'notok',
            data: { status: response.status },
        }
    }
}


export async function fetchAppSettings(appId: string): Promise<OkResponseState<TResGetSettingsApp> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/settings/apps/${appId}`, `${window.location.protocol}//${window.location.hostname}`);
    const response = await fetch(url);
    if (response.status === 200) {
        const body = await response.json() as TResGetSettingsApp;
        return {
            state: 'ok',
            data: body,
        }
    }
    else {
        return {
            state: 'notok',
            data: { status: response.status },
        }
    }
}

export async function fetchAppList(): Promise<OkResponseState<TAppData[]> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL('/notifications_backend/apps', `${window.location.protocol}//${window.location.hostname}`);
    const response = await fetch(url);
    if (response.status === 200) {
        const body = await response.json() as TAppData[];
        return { state: 'ok', data: body }
    }
    else {
        return { state: 'notok', data: { status: response.status } }
    }
}

export async function fetchTypes(): Promise<OkResponseState<TResGetSettingsTypes> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL('/notifications_backend/settings/types', `${window.location.protocol}//${window.location.hostname}`);
    const response = await fetch(url);
    if (response.status === 200) {
        const body = await response.json() as TResGetSettingsTypes;
        return { state: 'ok', data: body }
    }
    else {
        return { state: 'notok', data: { status: response.status } }
    }
}


export async function updateUserSettings(newSettings: TReqPostSettingsUser): Promise<OkResponseState<{}> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL('/notifications_backend/settings/user', `${window.location.protocol}//${window.location.hostname}`);
    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newSettings)
    });
    if (response.status === 200) {
        return { state: 'ok', data: {} }
    }
    else {
        return { state: 'notok', data: { status: response.status } }
    }
}

export async function updateAppSettings(appid: string, newSettings: TReqPostSettingsApp): Promise<OkResponseState<{}> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/settings/apps/${appid}`, `${window.location.protocol}//${window.location.hostname}`);
    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newSettings)
    });
    if (response.status === 200) {
        return { state: 'ok', data: {} }
    }
    else {
        return { state: 'notok', data: { status: response.status } }
    }
}

export async function getMailSetting(): Promise<OkResponseState<TResGetSettingsUserMail> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/settings/user/mail`, `${window.location.protocol}//${window.location.hostname}`)
    const response = await fetch(url);
    if (response.status === 200) {
        const data = await response.json() as TResGetSettingsUserMail;
        return { state: 'ok', data };
    }
    else {
        return { state: 'notok', data: { status: response.status } };
    }
}

export async function updateMailSetting(setting: THttpMailSetting): Promise<OkResponseState<{}> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/settings/user/mail`, `${window.location.protocol}//${window.location.hostname}`)
    const body: TReqPostSettingsUserMail = { setting };
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
    });
    if (response.status === 200) {
        return { state: 'ok', data: {} };
    }
    else {
        return { state: 'notok', data: { status: response.status } };
    }
}

export async function getNotificationCount(
    after: number | undefined,
    before: number | undefined,
    types: string[] | undefined,
    apps: string[] | undefined,
    unread: boolean = false
): Promise<OkResponseState<TResGetUserLastCheckedCenterCount> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/notifications/count`, `${window.location.protocol}//${window.location.hostname}`);
    url.searchParams.append('showInCenter', String(true));
    if (apps) {
        url.searchParams.append('apps', apps.join(','));
    }
    if (types) {
        url.searchParams.append('types', types.join(','));
    }
    if (after) {
        url.searchParams.append('after', String(after));
    }
    if (before) {
        url.searchParams.append('before', String(before));
    }
    if (unread) {
        url.searchParams.append('unread', String(unread));
    }
    const response = await fetch(url);
    if (response.status == 200) {
        return {
            state: 'ok',
            data: await response.json() as TResGetUserLastCheckedCenterCount,
        };
    }
    else {
        return { state: 'notok', data: { status: response.status } };
    }
}

/** mark the time the user last has seen the notification center */
export async function setNotificationsAsRead(appId?: string, type?: string): Promise<TResGetUserLastCheckedCenterCount> {
    const url = new URL(`/notifications_backend/notifications/markasread`, `${window.location.protocol}//${window.location.hostname}`);
    if (appId) {
        url.searchParams.append('apps', appId);
    }
    if (type) {
        url.searchParams.append('type', type);
    }
    const response = await fetch(url, {
        method: 'POST',
    });
    if (response.status == 200) {
        return await response.json() as TResGetUserLastCheckedCenterCount;
    }
    else {
        return { count: 0 }
    }
}

export async function getOnboarding(): Promise<OkResponseState<TResGetOnboarding> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/onboarding`, `${window.location.protocol}//${window.location.hostname}`);
    const response = await fetch(url);
    if (response.status === 200) {
        return {
            state: 'ok',
            data: await response.json() as TResGetOnboarding,
        }
    }
    else {
        return { state: 'notok', data: { status: response.status } };
    }
}

export async function setOnboarding(platform: 'web' | 'mobile' = 'web'): Promise<OkResponseState<{ status: 200 }> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/onboarding`, `${window.location.protocol}//${window.location.hostname}`);
    if (platform) {
        url.searchParams.append('platform', platform)
    }
    const response = await fetch(url, { method: 'POST' });
    if (response.status === 200) {
        return {
            state: 'ok',
            data: { status: 200 }
        }
    }
    else {
        return { state: 'notok', data: { status: response.status } };
    }
}


/**
 * Send the selected push Notification state to the backend
 * and update the internal component states
 */
export async function sendPushNotificationPermission(
    permission: 'allowed' | 'denied',
    permissionState: Ref<'allowed' | 'denied' | 'not-set'>,
    errorState: Ref<boolean>
) {
    try {
        permissionState.value = permission;
        const response = await fetch("/notifications_backend/settings/user/data-protection", {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                pnPermission: permission
            }),
        });
        if (response.status !== 200) {
            errorState.value = true;
        }
    }
    catch(e) {
        errorState.value = true;
        console.error(e);
    }
}

export async function fetchEventMetadata(): Promise<OkResponseState<Record<string, TEvent>> | NotOkResponseState<DefaultNotOk>> {
    const url = new URL(`/notifications_backend/events`, `${window.location.protocol}//${window.location.hostname}`)
    const response = await fetch(url);
    if (response.status === 200) {
        const data = await response.json() as Record<string, TEvent>;
        return { state: 'ok', data };
    }
    else {
        return { state: 'notok', data: { status: response.status } };
    }
}
