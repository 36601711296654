<template>
<LaHighlightedMessage level="none">
    <h3>{{ t('components.noNotificationsView.header') }}</h3>
    <template v-if="state.state === 'ok'">
        <template v-if="state.data.count > 0">
            <p>{{ t('components.noNotificationsView.notificationsAvailableWithoutFilters_1') }}</p>
            <p>{{ t('components.noNotificationsView.notificationsAvailableWithoutFilters_2', { n: state.data.count }) }}</p>
            <p>
                <LaButton v-if="props.showResetApp"
                    color="primary"
                    @click="emit('resetApps')"
                >
                    {{ t('buttons.resetAppFilter') }}
                </LaButton>
                <LaButton v-if="props.showResetType"
                    color="primary"
                    @click="emit('resetTypes')"
                >
                    {{ t('buttons.resetTypeFilter') }}
                </LaButton>
                <LaButton v-if="props.showResetDate"
                    color="primary"
                    @click="emit('resetDate')"
                >
                    {{ t('buttons.resetDateFilter') }}
                </LaButton>
            </p>
        </template>
        <template v-else>
            <p>{{ t('components.noNotificationsView.noNotificationsAvailable') }}</p>
            <p>
                <i18n-t keypath="components.noNotificationsView.directUserToSettings">
                    <template v-slot:settings>
                        <RouterLink to="/settings">
                            <span style="white-space: nowrap;">
                                <i class="fa-light fa-gear"></i> {{ t('nav.settings') }}
                            </span>
                        </RouterLink>
                    </template>
                </i18n-t>
            </p>
        </template>
    </template>
</LaHighlightedMessage>
</template>

<script setup lang="ts">
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { useI18n } from 'vue-i18n';
import { deGlobal } from '@/i18n/de';
import { enGlobal } from '@/i18n/en';
import { getNotificationCount, useFetch } from '@/utils/fetchplus';
import { onMounted } from 'vue';
import LaHighlightedMessage from './LaHighlightedMessage.vue';

const props = withDefaults(
    defineProps<{
        before?: number,
        after?: number,
        apps?: string[],
        types?: string[],
        showResetApp: boolean,
        showResetDate: boolean,
        showResetType: boolean,
    }>(),
    {
        showResetApp: false,
        showResetType: false,
        showResetDate: false,
    }
);

const emit = defineEmits<{
    (ev: 'resetApps'): void,
    (ev: 'resetTypes'): void,
    (ev: 'resetDate'): void,
}>();

const { state, executeFetch } = useFetch(getNotificationCount)
const { t } = useI18n({
    messages: { de: deGlobal, en: enGlobal }
});

onMounted(() => {
    executeFetch(
        props.after,
        props.before,
        props.types,
        props.apps,
        false,
    );
});
</script>
<style>
</style>