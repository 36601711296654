<template>
    <div class="logo-container">
      <svg ref="svgRef" class="svg" viewBox="0 0 540 453" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g transform="matrix(1,0,0,1,-1083.37,-1130.32)">
            <g transform="matrix(3.03666,0,0,3.36663,1073.11,3330.43)">
                <g transform="matrix(1.03302,0,0,0.931769,-240.743,-653.505)">
                    <path d="M354.11,82.42L332.91,103.62C317.18,119.35 268.91,111.74 236.32,115.42L257.52,94.22C290.07,90.54 338.39,98.14 354.11,82.42Z" class="dna_hor_bottom"/>
                </g>
            </g>
            <g transform="matrix(3.03666,0,0,3.36663,1073.11,3330.43)">
                <g transform="matrix(1.03302,0,0,0.931769,-240.743,-653.505)">
                    <path d="M278.37,144.2C282.12,111.64 274.44,77.3 290.2,61.53L302,49.75L311.42,40.33C295.64,56.1 303.32,90.45 299.57,123L278.37,144.2Z" class="dna_vert_left"/>
                </g>
            </g>
            <g transform="matrix(3.03666,0,0,3.36663,1073.11,3330.43)">
                <g transform="matrix(1.03302,0,0,0.931769,-240.743,-653.505)">
                    <path d="M387,49.7C354.49,53.45 306,45.77 290.2,61.53L311.4,40.33C327.16,24.57 375.69,32.25 408.24,28.5L387,49.7Z" class="dna_hor_top"/>
                </g>
            </g>
            <g transform="matrix(3.03666,0,0,3.36663,1073.11,3330.43)">
                <g transform="matrix(1.03302,0,0,0.931769,-240.743,-653.505)">
                    <path d="M353.94,82.42L332.74,103.62C348.46,87.9 340.86,53.76 344.54,21.2L365.74,0C362.06,32.55 369.66,66.7 353.94,82.42Z" class="dna_vert_right"/>
                </g>
            </g>
        </g>
      </svg>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const svgRef = ref<SVGElement>();

onMounted(() => {
    setTimeout(() => {
        if (svgRef.value && 'getAnimations' in svgRef.value) {
            const animations = svgRef.value.getAnimations({ subtree: true }) as any[];
            console.log(`Fix bundling error which throws away animations without this logging`, animations);
            const topAnimation = animations.find((ani) => ani.animationName.indexOf('top') >= 0);
            const bottomAnimation = animations.find((ani) => ani.animationName.indexOf('bottom') >= 0);
            const rightAnimation = animations.find((ani) => ani.animationName.indexOf('right') >= 0);
            const leftAnimation = animations.find((ani) => ani.animationName.indexOf('left') >= 0);
            bottomAnimation?.play();
            setTimeout(() => {
                leftAnimation?.play();
            }, 250);
            let iteration = 0;
            bottomAnimation?.addEventListener('finish', () => {
                if (iteration === 0)
                rightAnimation?.play();
                else
                rightAnimation?.reverse();
            });
            leftAnimation?.addEventListener('finish', () => {
                if (iteration === 0)
                topAnimation?.play();
                else
                topAnimation?.reverse();
            });
            topAnimation?.addEventListener('finish', () => {
                leftAnimation?.reverse();
            });
            rightAnimation?.addEventListener('finish', () => {
                bottomAnimation?.reverse();
                iteration++;
            });
        }
    }, 50);
})

</script>

<style scoped>

.svg {
    width: 100%;
    height: 100%;
}

.dna_hor_bottom,
.dna_hor_top,
.dna_vert_left,
.dna_vert_right
{
    --base-color: rgb(181, 181, 181);
}

.dna_hor_bottom {
    animation: dna_hor_bottom 0.5s linear;
    animation-play-state: paused;
    animation-fill-mode: both;
}

.dna_hor_top {
    animation: dna_hor_top 0.5s linear;
    animation-play-state: paused;
    animation-fill-mode: both;
}

.dna_vert_left {
    animation: dna_vert_left 0.5s linear;
    animation-play-state: paused;
    animation-fill-mode: both;
}

.dna_vert_right {
    animation: dna_vert_right 0.5s linear;
    animation-play-state: paused;
    animation-fill-mode: both;
}

@keyframes dna_hor_bottom {
    0% {
        fill: var(--base-color);
    }
    75% {
        fill: rgb(106,192,121);
    }
    100% {
        fill: rgb(106,192,121);
    }
}

@keyframes dna_hor_top {
    0% {
        fill: var(--base-color);
        z-index: 10;
    }
    50% {
        z-index: 10;
    }
    75% {
        fill: rgb(246,138,53);
        z-index: 0;
    }
    100% {
        fill: rgb(246,138,53);
    }
}

@keyframes dna_vert_left {
    0% {
        fill: var(--base-color);
    }
    75% {
        fill: rgb(239,70,85);
    }
    100% {
        fill: rgb(239,70,85);;
    }
}

@keyframes dna_vert_right {
    0% {
        fill: var(--base-color);
    }
    75% {
        fill: rgb(29,161,220);
    }
    100% {
        fill: rgb(29,161,220);
    }
}

</style>