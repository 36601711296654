import mitt from "mitt";

type Events = {
    setNotificationsAsRead: Date,
}

export const globalEventBus = mitt<Events>();

window.addEventListener('focus', () => {
    globalEventBus.emit('setNotificationsAsRead', new Date());
});