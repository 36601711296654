<script setup lang="ts">
import DataprotectionHint from '@/components/DataprotectionHint.vue';
import { registerClientForPushNotifications } from '@/fb';
import { useAppStore } from '@/store';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { useIntervalFn } from '@vueuse/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import pageStyles from '@/styles/page.module.css';

const { t } = useI18n();
const appStore = useAppStore();

const arePNDisabledInBrowser = ref(Notification.permission === 'denied');
const arePNDisabledByStorageEntry = ref(localStorage.getItem('no-push-notifications') !== null || sessionStorage.getItem('tmp-no-push-notifications') !== null);

useIntervalFn(() => {
    arePNDisabledInBrowser.value = Notification.permission === 'denied';
    arePNDisabledByStorageEntry.value = localStorage.getItem('no-push-notifications') !== null || sessionStorage.getItem('tmp-no-push-notifications') !== null;
}, 100);

/** cleanup all disabling elements and trigger the registration */
function allowPN() {
    sessionStorage.removeItem('tmp-no-push-notifications');
    localStorage.removeItem('no-push-notifications');
    appStore.isPopperOpen = false;
    appStore.isModalOpen = false;
    registerClientForPushNotifications();
}
</script>

<template>
<div :class="pageStyles.content + ' content'">
    <template v-if="arePNDisabledInBrowser">
        <div v-html="t('pages.pushNotificationSettings.browserDescriptions.enableHtml')"></div>
    </template>
    <template v-else-if="arePNDisabledByStorageEntry">
        <h3>{{ t('pages.pushNotificationSettings.clearBlockingPersistentTokens.header') }}</h3>
        <p>{{ t('pages.pushNotificationSettings.clearBlockingPersistentTokens.message') }}</p>
        <p>
            <LaButton @click="allowPN" color="primary">{{ t('pages.pushNotificationSettings.clearBlockingPersistentTokens.button') }}</LaButton>
        </p>
    </template>
    <template v-else>
        <div v-html="t('pages.pushNotificationSettings.browserDescriptions.disableHtml')"></div>
    </template>

    <DataprotectionHint />
</div>
</template>
<style scoped>
.content {
    padding-top: 0;
}
</style>