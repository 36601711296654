<template>
<div class="lan-card">
    <template v-if="isOk">
        <div v-if="isLoading" class="lan-loading-indicator"><Loading /></div>
        <!-- Event Description -->
        <h3>{{ props.name[locale] }}</h3>

        <p>{{ props.description[locale] }}</p>

        <!-- Checkboxen for the setttings -->
        <div class="lan-settings-container">
            <div class="lan-item" @click="toggleUserSetting('showInCenter')">
                <Checkbox :checked="showInCenter"></Checkbox>
                {{ t('components.appSetting.showInCenter') }}
            </div>
            <div class="lan-item" @click="toggleUserSetting('sendMail')">
                <Checkbox :checked="sendMail"></Checkbox>
                {{ t('components.appSetting.sendMail') }}
            </div>
            <div class="lan-item" @click="toggleUserSetting('sendPushNotification')">
                <Checkbox :checked="sendPushNotification"></Checkbox>
                {{ t('components.appSetting.sendPushNotification') }}
            </div>
        </div>
        <!-- overwriting apps -->
        <template  v-if="props.overwritingApps && props.overwritingApps.length > 0">
            <div>
                <h5>
                    {{ t('components.userSetting.overwrittenAppsExists', { count: props.overwritingApps.length }) }}
                    <LaButton  v-if="!showOverwritingApps" @click="showOverwritingApps = true" color="primary">{{ t('buttons.showOverwrittenApps') }}</LaButton>
                    <LaButton  v-else @click="() => {showOverwritingApps = false; emit('closing-overwrittenapps')}" color="primary">{{ t('buttons.hideOverwrittenApps') }}</LaButton>
                </h5>
            </div>

            <Transition>
                <div v-if="showOverwritingApps">
                    <div :class="gridStyles.container">
                        <template
                            v-for="app in props.overwritingApps"
                            :key="app.appId"
                        >
                            <div :class="gridStyles.seperator"></div>
                            <div
                                :class="`${gridStyles.entry} lan-overwritingapp-entry`"
                            >
                                <div>
                                    <img
                                        v-if="app.appId"
                                        :src="imgProxy(app.icon, { h: 32, w: 32 }, true)"
                                        class="lan-ova-img"
                                    />
                                </div>
                                <div>
                                    <h3 class="lan-ova-name">{{ app.appName }}</h3>
                                </div>
                                <div style="flex: 1;"></div>
                                <div class="lan-ova-button" @click="onClickAppSetting(app.appId)">
                                    {{ t('nav.appSettings') }}
                                    <i class="fa-light fa-chevron-right lan-arrow-icon"></i>
                                </div>
                            </div>

                        </template>
                        <div v-if="props.overwritingApps.length % 2 === 1" :class="gridStyles.spacer"></div>
                    </div>
                </div>
            </Transition>
        </template>
    </template>
    <template v-else>
        <h3>{{ t('retryLater') }}</h3>
    </template>
</div>
</template>
<script setup lang="ts">
import { updateUserSettings, useFetch, updateAppSettings } from '@/utils/fetchplus';
import { computed, ref, toRefs } from 'vue';
import Loading from './Loading.vue';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { useI18n } from 'vue-i18n';
import { SupportedLocale } from '@/i18n';
import { enGlobal } from '@/i18n/en';
import { deGlobal } from '@/i18n/de';
import { TI18N } from '@/models/common';

import 'animate.css';
import Checkbox from './Checkbox.vue';
import gridStyles from '@/styles/grid.module.css';
import { imgProxy } from '@/utils/imgproxy';
import { useRouter } from 'vue-router';

const props = defineProps<{
    type: string;
    name: TI18N;
    description: TI18N,
    showInCenter: boolean,
    sendMail: boolean,
    sendPushNotification: boolean,
    overwritingApps?: {
        appId: string;
        appName: string;
        appDescription: string;
        icon: string;
        showInCenter: boolean,
        sendMail: boolean,
        sendPushNotification: boolean,
    }[];
    displayPushNotificationOptions: boolean;
    isMobile: boolean;
}>();
const { showInCenter, sendMail, sendPushNotification } = toRefs(props);

const emit = defineEmits<{
    (ev: 'closing-overwrittenapps'): void;
}>();

const { state: fetchState, executeFetch } = useFetch(updateUserSettings);
const { state: fetchAppState } = useFetch(updateAppSettings);
const router = useRouter();
const { locale: tmpLocale, t } = useI18n({
    messages: { en: enGlobal, de: deGlobal }
});
const locale = computed(() => (tmpLocale.value as string).substring(0, 2) as SupportedLocale);

const isLoading = computed(() => {
    return fetchState.value.state === 'loading' || fetchAppState.value.state === 'loading';
});

const isOk = computed(() => {
    if (fetchState.value.state === 'notok' || fetchState.value.state === 'error')
        return false;
    if (fetchAppState.value.state === 'notok' || fetchAppState.value.state === 'error')
        return false;
    return true;
});

const showOverwritingApps = ref(false);

/** toggle the differnt settings. */
function toggleUserSetting(type: 'showInCenter' | 'sendMail' | 'sendPushNotification') {
    if (type === 'showInCenter') {
        showInCenter.value = !showInCenter.value;
    }
    else if (type === 'sendMail') {
        sendMail.value = !sendMail.value;
    }
    else if (type === 'sendPushNotification') {
        sendPushNotification.value = !sendPushNotification.value;
    }
    else {
        return;
    }
    executeFetch({
        type: props.type,
        showInCenter: showInCenter.value,
        sendMail: sendMail.value,
        sendPushNotification: sendPushNotification.value,
    });
}

// function handleSelectAppSetting(appId: string) {
//         executeFetchApp(appId, { type: props.type, inherit: true, sendMail: false, sendPushNotification: false, showInCenter: false });
// }

function onClickAppSetting(appId: string) {
    if (props.isMobile) {
        ///@ts-ignore
        window.nativePostMessage({
            type: 'navigation',
            page: 'pn-appsettings',
            appId
        });
    }
    else {
        router.push(`/settings/app/${appId}?showBack=true`)
    }
}

</script>
<style scoped>

h5 {
    margin: 0;
}

.lan-settings-container {
    display: block;
    width: 100%;
}

.lan-item {
    width: 100%;
}

.lan-item:hover {
    background-color: #eee;
}

.lan-loading-indicator {
    position: absolute;
    top: 5px;
    right: 8px;
    height: 20px;
    width: 20px;
}
.lan-card {
    margin: 0 0 20px 0;
    padding: 10px;
    box-shadow: 0 1px 5px rgba(0,0,0,.2);
    position: relative;
}

.lan-card:last-of-type {
    margin: 0;
}

.lan-overwritingapp-entry {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-items: center;
    align-items: center;
    padding: 5px;
    flex-wrap: wrap;
    transition: max-height 0.2s linear, padding 0.2s linear;
    overflow: hidden;
}

.lan-overwritingapp-entry:hover {
    background-color: #eee;
}

.lan-overwritingapp-entry:last-of-type {
    margin: 5px 0 0 0;
}

.lan-ova-img {
    border-radius: 4px;
    height: 32px;
    width: 32px;
}

.lan-ova-name {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 400;
}

.lan-ova-button {
    cursor: pointer;
    height: fit-content;
    color: #008bd2;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s linear;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-from .lan-overwritingapp-entry,
.v-leave-to .lan-overwritingapp-entry {
  max-height: 0px;
  padding: 0px 5px;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}

.v-enter-to .lan-overwritingapp-entry,
.v-leave-from .lan-overwritingapp-entry {
  max-height: 50px;
  padding: 5px
}
</style>