<script setup lang="ts">
import LaConfirm from '@ll/form-utils/src/components/LaConfirm.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import LaHighlightedMessage from '@/components/LaHighlightedMessage.vue';
import DataprotectionHint from '@/components/DataprotectionHint.vue';

const props = defineProps<{
    resolveFn: Function;
}>();

const { t } = useI18n();

const buttons = computed(() => [
    {
        label: 'Erlauben',
        key: 'ok',
        clear: false,
        size: 'large',
        color: 'primary',
        value: 'ok'
    },
    {
        label: 'Ablehnen',
        key: 'cancel',
        clear: true,
        size: 'large',
        color: 'primary',
        value: 'cancel'
    },
    {
        label: 'Später',
        key: 'later',
        clear: true,
        size: 'large',
        color: 'primary',
        value: 'later'
    },
] as any);

</script>
<template>
<LaConfirm
    :open="true"
    :header="t('apps.pnRegistration.header')"
    :dismissValue="undefined"
    :buttons="buttons"
    @resolve-value="v => props.resolveFn(v)"
>
    <p>{{ t('apps.pnRegistration.message') }}</p>
    <LaHighlightedMessage level="none">{{ t('apps.pnRegistration.warning') }}</LaHighlightedMessage>
    <DataprotectionHint />
</LaConfirm>
</template>