<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';


type Level = 'info' | 'warning' | 'error' | 'none';

const props = withDefaults(
    defineProps<{
        level: Level,
    }>(),
    {
        level: 'info',
    }
);

const { t } = useI18n({
    messages: {
        en: {
            headers: {
                info: 'info',
                warning: 'warning',
                error: 'error'
            }
        },
        de: {
            headers: {
                info: 'Information',
                warning: 'Warnung',
                error: 'Fehler'
            }
        }
    }
});

const classMap = computed(() => ({
    'lla-highlighted-message': true,
    'lla-highlighted-info': props.level === 'info',
    'lla-highlighted-none'  : props.level === 'none',
    'lla-highlighted-warning': props.level === 'warning',
    'lla-highlighted-error': props.level === 'error',
}));

</script>
<template>
<div :class="classMap">
    <h4 v-if="props.level !== 'none'">{{ t(`headers.${props.level}`) }}</h4>
    <div>
        <slot></slot>
    </div>
</div>
</template>
<style scoped>
.lla-highlighted-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
}

.lla-highlighted-info {
    background-color: #ffffeb;
    border-color: rgba(0, 0, 0, 0.133);
}

.lla-highlighted-none {
    border-color: rgba(0, 0, 0, 0.133);
    background-color: #ffffeb;
}

.lla-highlighted-warning {
    background-color: #f0decf;
    border-color: #e0be9f;
}

.lla-highlighted-error {
    background-color: #ecd4d4;
    border-color: rgba(187,34,34,0.392);
}
</style>