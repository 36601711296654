import { createPinia } from 'pinia';
import AppBellApp from './AppBell.vue';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { defineRouter } from './router';
import { deGlobal } from '@/i18n/de';
import { enGlobal } from '@/i18n/en';

export function createAppLaAppBell(mountTarget: HTMLElement, appId: string, lang: 'de-DE' | 'en-US' = 'en-US') {
    const app = createApp(AppBellApp, {
        appId,
    });
    const pinia = createPinia();
    const i18n = createI18n({
        legacy: false,
        locale: lang,
        fallbackLocale: 'en',
        messages: {
            de: deGlobal,
            en: enGlobal,
        },
        datetimeFormats: {
            'en-US': {
                short: {
                    month: 'short',
                    day: '2-digit',
                }
            },
            "de-DE": {
                short: {
                    month: 'short',
                    day: '2-digit'
                }
            }
        },
    });
    const router = defineRouter(appId);
    app.use(pinia);
    app.use(i18n);
    app.use(router);
    app.mount(mountTarget);
}