<template>
<template v-if="fetchState.state === 'ok'">
    <!-- navigation for small screens -->
    <div :class="pageStyles.content + ' content'">
        <h3>LivingApp zur Änderung der Einstellungen auswählen</h3>
        <!-- Description + Searchbar -->
        <Searchbar
            v-if="showSearchBar"
            :value="searchbarInput"
            @input="v => searchbarInput = v"
        />
    </div>
    <div  v-if="filteredAppList.length > 0" :class="gridStyles.container">
        <template
            v-for="app in filteredAppList"
            :key="app.appId"
        >
            <div :class="gridStyles.seperator"></div>
            <div
                :class="`${gridStyles.entry} lan-app-entry`"
                @click="router.push(`/settings/app/${app.appId}?showBack=true`)"
            >
                <div class="lan-icon" :style="{ backgroundImage: `url('${imgProxy(app.icon, { h: 64, w: 64 }, true)}')`}" ></div>
                <div class="lan-content">
                    <h4>{{ app.name }}</h4>
                    <p>{{ app.description }}</p>
                </div>
                <i class="fa-light fa-chevron-right lan-arrow-icon"></i>
            </div>
        </template>
    </div>
    <!-- messages if no apps are in filter -->
    <template v-else-if="searchInputDebounced.length > 0">
        <div :class="pageStyles.content">
            <h3>{{ t('pages.appSettingsNav.searchNoAppFound', { input: searchInputDebounced }) }}</h3>
            <p>
                <LaButton
                    @click="searchbarInput = ''"
                    color="primary"
                >
                    {{ t('buttons.resetSearch') }}
                </LaButton>
            </p>
        </div>
    </template>
    <template v-else>
        <div :class="pageStyles.content">
            <h3>{{ t('pages.appSettingsNav.noAppAvailable.header') }}</h3>
            <p>{{ t('pages.appSettingsNav.noAppAvailable.description') }}</p>
        </div>
    </template>
</template>
<!-- Loading View -->
<template v-else-if="['loading', 'init'].indexOf(fetchState.state) >= 0">
    <div :class="pageStyles.content">
        <Loading :class="loadingStyles.loading"/>
    </div>
</template>
<!-- Error View -->
<template v-else-if="fetchState.state === 'notok' || fetchState.state === 'error'">
    <div :class="pageStyles.content">
        <ErrorView />
    </div>
</template>
</template>
<script setup lang="ts">
import { fetchAppList, useFetch } from '@/utils/fetchplus';
import { computed, onMounted, ref, watch } from 'vue';
import { imgProxy } from '@/utils/imgproxy';
import { useRouter } from 'vue-router';
import Loading from '@/components/Loading.vue';
import Searchbar from '@/components/Searchbar.vue';
import { debouncedRef } from '@vueuse/core';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import ErrorView from '@/components/ErrorView.vue';
import { useI18n } from 'vue-i18n';

import loadingStyles from '@/styles/loading.module.css';
import pageStyles from '@/styles/page.module.css';
import gridStyles from '@/styles/grid.module.css';
import { enGlobal } from '@/i18n/en';
import { deGlobal } from '@/i18n/de';


const { state: fetchState, executeFetch } = useFetch(fetchAppList);
const router = useRouter();

const searchbarInput = ref(sessionStorage.getItem('searchbarInput-AppSettingsNavPage') ?? '');
const searchInputDebounced = debouncedRef(searchbarInput, 500);
const {t} = useI18n({ messages: {
    de: deGlobal,
    en: enGlobal
}});

onMounted(() => {
    executeFetch();
});

const filteredAppList = computed(() => {
    if (fetchState.value.state === 'ok') {
        if (searchInputDebounced.value) {
            return fetchState.value.data.filter(app => `${app.name.toLowerCase()} ${app.description.toLowerCase()}`.indexOf(searchInputDebounced.value.toLowerCase()) >= 0)
        }
        return fetchState.value.data
    }
    return []
});

const showSearchBar = computed(() => {
    if (fetchState.value.state === 'ok')
        return fetchState.value.data.length > 10
    return false
});

watch(searchbarInput, (v) => {
    sessionStorage.setItem('searchbarInput-AppSettingsNavPage', v);
});

</script>

<style scoped>

.content {
    padding-top: 0;
    margin: 0;
}

.lan-app-entry {
    cursor: pointer;
    display: flex;
    gap: 20px;
    align-self: stretch;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    transition: background .3s ease-out;
}

.lan-app-entry:hover {
    background-color: rgb(235, 238, 240);
}

.lan-app-entry:last-of-type {
    margin-bottom: 0;
}
.lan-content {
    flex: 1;
}

.lan-content p {
    margin: 0;
}

.lan-content h4 {
    color: #168dd0;
}

.lan-icon {
    align-self: center;
    width: 64px;
    height: 64px;
    border: 2px solid #dedede;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.lan-arrow-icon {
    margin-right: 10px;
    font-size: 20px;
}
.lan-custom-padding {
    padding-top: 0;
}

@media screen and (min-width: 992px) {
    .lan-custom-padding {
        padding-top: 20px;
    }
}
</style>