<template>
    <div class="close-modal" @click="store.isModalOpen = false">
        ×
    </div>
</template>
<script setup lang="ts">
import { useAppStore } from '@/store';

const store = useAppStore();
</script>
<style scoped>
.close-modal {
    /* positioning */
    /* sizing */
    width: 44px;
    height: 44px;
    padding: 0;
    margin-right: -12px;
    /* font */
    line-height: 44px;
    text-decoration: none;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    color: #333;
    /* appearance */
    opacity: .65;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-family: Arial,Baskerville,monospace;
    background-color: transparent;
    box-shadow: none;
}
</style>