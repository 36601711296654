<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const displayDetails = ref(false);
const { t } = useI18n();
</script>
<template>
    <slot name="summary" :display-details="displayDetails"></slot>
    <template v-if="!displayDetails">
        <p>
            <a @click="displayDetails = true">{{ t('components.details.more') }}</a>
        </p>
    </template>
    <template v-else>
        <slot :display-details="displayDetails"></slot>
        <p>
            <a @click="displayDetails = false">{{ t('components.details.less') }}</a>
        </p>
    </template>
</template>
<style scoped>

</style>