import { version } from '../package.json';

import 'animate.css';
import { createAppGlobalBell } from "./apps/GlobalBell/app";
import { createAppLaAppBell } from './apps/LaAppBell/app';
import { registerClientForPushNotifications } from './fb';

console.info(`LOADED REALTIME NOTIFICATION LIBRARY v${version}`);

export { createAppGlobalBell } from "./apps/GlobalBell/app";
export { createAppLaAppBell } from './apps/LaAppBell/app';
export { registerClientForPushNotifications } from './fb'

export async function initDebug() {
  console.info('run notification DEBUG version ' + version);

  const styleContainer = document.createElement('link');
  styleContainer.rel = "stylesheet";
  styleContainer.type = "text/css";
  styleContainer.href = "/notifications/style.css";
  document.head.appendChild(styleContainer);

  const maybeUserMenuEl = document.getElementsByClassName('usermenu')[0];
  if (maybeUserMenuEl) {
    const el = document.createElement('div');
    el.style.position = 'relative';
    maybeUserMenuEl.prepend(el);
    createAppGlobalBell(el, 'de-DE');
  }

  if (window.location.href.match(/\/apps\/.+\.htm/)) {
    const appId = window.location.pathname.split('/').at(-1)!.split('.')[0]!;
    const mountTarget = document.createElement('div');
    const container = document.querySelector('.mylivingapps-detail-app .contextmenu-button') as HTMLDivElement;
    if (!container)
      return;
    container.appendChild(mountTarget);
    container.style.display = 'flex';
    container.style.flexDirection = 'row-reverse';
    mountTarget.style.paddingTop = '10px';
    mountTarget.style.position = 'relative';
    createAppLaAppBell(mountTarget, appId, 'de-DE');
  }

  registerClientForPushNotifications();
}

export async function initLa() {
  const abResponse = await fetch('/notifications_backend/abtest');
  if (abResponse.status === 200) {
    let container = document.getElementsByClassName("global-notification-bell")[0];
    if (container) {
      if (navigator.serviceWorker) {
        registerClientForPushNotifications();
      }
      createAppGlobalBell(container as HTMLElement, 'de-DE');
    }
  
    let containerApp = document.getElementsByClassName("app-detail-notification-bell")[0];
    if (containerApp) {
      if (window.location.pathname.indexOf('/apps/') >= 0) {
      const appId = window.location.pathname.split('/').at(window.location.pathname.split('/').length-1)?.split('.')[0];
      if (containerApp && appId) {
        createAppLaAppBell(containerApp as HTMLElement, appId, 'de-DE');
      }
      }
    }
  }
}