<template>
<div :class="pageStyles.page">
    <div :class="pageStyles.bar">
        <h3></h3>
        <div :class="navigationStyles.lgScreen">
            <RouterLink to="/filter/help">
                <i class="fa-light fa-question"></i> {{ t('nav.help') }}
            </RouterLink>
        </div>
        <CloseModalButton />
    </div>
    <div :class="pageStyles.content" style="padding-bottom: 0">
        <div :class="navigationStyles.smScreen">
        <LaButton
            size="expand"
            color="primary"
            :class="navigationStyles.buttonWithIcon"
            style="padding-bottom: 0;"
            @click="router.push('/filter/help')"
        >
            <i class="fa-light fa-question"></i> {{ t('nav.help') }}
        </LaButton>
    </div>
</div>
    <Filter :app-id="props.appId" :is-mobile="false"></Filter>
</div>
</template>
<script setup lang="ts">
import CloseModalButton from '@/components/CloseModalButton.vue';
import pageStyles from '@/styles/page.module.css';
import navigationStyles from '@/styles/navigation.module.css';
import { useI18n } from 'vue-i18n';
import Filter from '../content/Filter.vue';
import { useRouter } from 'vue-router';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
const props = defineProps<{
    appId?: string;
}>();
const { t } = useI18n();
const router = useRouter();
</script>