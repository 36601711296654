<template>
<div v-if="!(fetchState.state === 'ok' && fetchState.data.length > 0)"
    :class="pageStyles.content" 
    style="padding-top: 0"
>
    <template v-if="store.selectedAppsForList?.length === 0">
        <FiltersNotValidView
            @reset="handleReset"
        />
    </template>
    <template v-else-if="fetchState.state === 'ok' && fetchState.data.length === 0">
        <NoNotificationsView
            :after="props.after"
            :before="props.before"
            :types="[props.type]"
            :show-reset-app="(store.selectedAppsForList ?? []).length > 0 && isBellAllApp"
            @reset-apps="handleReset"
        />
    </template>
    <template v-else-if="['loading', 'init'].indexOf(fetchState.state) >= 0">
        <Loading :class="loadingStyles.loading"/>
    </template>
    <template v-else-if="fetchState.state === 'notok' || fetchState.state === 'error'">        
        <ErrorView />
    </template>
</div>
<template v-else>
    <List
        notification-class="lan-notification-padding-modal"
        :data="fetchState.data"
        :app-data="appMap"
        :event-data="eventMap"
        :is-displayed-in-modal="true"
        :is-mobile="props.isMobile"
        @open-more="loadEventMetadata"
    />
</template>
</template>
<script setup lang="ts">
import List from '@/components/List.vue';
import { useFetch, fetchNotificationLatest, fetchAppList, fetchEventMetadata } from '@/utils/fetchplus';
import { ComputedRef, computed, onActivated, ref } from 'vue';
import Loading from '@/components/Loading.vue';
import { useAppStore } from '@/store';
import ErrorView from '@/components/ErrorView.vue';
import NoNotificationsView from '@/components/NoNotificationsView.vue';
import FiltersNotValidView from '@/components/FiltersNotValidView.vue';
import { TAppData } from '@/models/common';
import { TEvent } from '@/models/responses';

import loadingStyles from '@/styles/loading.module.css';
import pageStyles from '@/styles/page.module.css';


const props = defineProps<{
    after: number;
    before: number;
    type: string;
    appId?: string;
    isMobile: boolean;
}>();

const { state: fetchState, executeFetch } = useFetch(fetchNotificationLatest);
const { state: appListFetchState, executeFetch: executeFetchAppList } = useFetch(fetchAppList);
const { state: eventMapFetchState, executeFetch: executeFetchEventMap } = useFetch(fetchEventMetadata);
const store = useAppStore();

const appMap: ComputedRef<Record<string, TAppData> | undefined> = computed(() => {
    if (appListFetchState.value.state === 'ok') {
        return Object.fromEntries(appListFetchState.value.data.map(x => [x.appId, x]));
    }
    return undefined;
});

const isBellAllApp = props.appId === undefined;

const eventMap = ref<Record<string, TEvent> | undefined>();

onActivated(() => {
    executeIntialFetch();
    executeFetchAppList();
});

function executeIntialFetch() {
    if (isBellAllApp) {
        executeFetch(props.after, props.before, [props.type], store.selectedAppsForList);
    }
    else {
        executeFetch(props.after, props.before, [props.type], [props.appId]);
    }
}

function handleReset() {
    store.selectedAppsForList = undefined;
    executeIntialFetch();
}

async function loadEventMetadata() {
    if (eventMap.value === undefined) {
        await executeFetchEventMap();
        if (eventMapFetchState.value.state === 'ok') {
            eventMap.value = eventMapFetchState.value.data;
        }
    }
}
</script>