<script setup lang="ts">
import LaHighlightedMessage from '@/components/LaHighlightedMessage.vue';
import { displayNotificationInformationModal, registerClientForPushNotifications } from '@/fb';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits<{
    (ev: 'allowed'): void
}>();

const { t } = useI18n();

async function allowPushNotifications() {
    await displayNotificationInformationModal();
    registerClientForPushNotifications();
    emit('allowed');
}
</script>
<template>
    <!-- display the user to allow push notifications -->
    <LaHighlightedMessage style="margin: 0 10px" :level="'none'" >
        <div v-html="t('components.allowPn.message')">
        </div>
        <LaButton color="primary" @click="allowPushNotifications">{{ t('components.allowPn.button') }}</LaButton>
    </LaHighlightedMessage>
</template>