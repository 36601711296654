<template>
    <LaHighlightedMessage level="none">
        <h3>{{ t('components.privacyHint.header') }}</h3>
        <div v-html="t('components.privacyHint.message')">
        </div>
    </LaHighlightedMessage>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import LaHighlightedMessage from './LaHighlightedMessage.vue';
const { t } = useI18n();
</script>
<style scoped>
</style>