import { base64 } from "rfc4648";
import { notificationMetadataStore } from "./notificationmetastore";

export type PayloadDecrypted = {
    title: string;
    message: string;
    target?: string;
    icon?: string;
    appId: string;
    type: string;
    recordId?: string;
}

export async function decryptNotification(base_64Data: string, base_64IV: string): Promise<PayloadDecrypted> {
    const maybeAesKeyFromStore = await notificationMetadataStore.getItem<string>('notification-aes-key');
    if (maybeAesKeyFromStore === null) {
        throw new Error("no aes key stored");
    }
    const aesKeyString = maybeAesKeyFromStore;
    const ivByteArray = base64.parse(base_64IV);
    const dataByteArray = base64.parse(base_64Data);
    const aesKeyByteArray = base64.parse(aesKeyString);
    const aesKey = await crypto.subtle.importKey('raw', aesKeyByteArray, 'AES-GCM', false, ['decrypt']);
    const decryptedArrayBuffer = await crypto.subtle.decrypt({ name: 'AES-GCM', iv: ivByteArray }, aesKey, dataByteArray);
    const decryptedByteArray = new Uint8Array(decryptedArrayBuffer);
    const utf8Decoder = new TextDecoder();
    const decryptedString = utf8Decoder.decode(decryptedByteArray);
    return JSON.parse(decryptedString) as PayloadDecrypted;
}