<template>
<i class="fa-solid fa-chevron-left back-button" @click="router.back()"></i>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter();
</script>
<style scoped>
.back-button {
    font-size: 30px;
    cursor: pointer;
    color: #168dd0;
}
</style>