import { enGlobal } from "./en";

export const deGlobal: typeof enGlobal = {
    apps: {
        bells: {
            header: 'Mitteilungen',
            forApp: 'für',
            noNotifications: 'Aktuell sind keine Mitteilungen für Sie verfügbar.'
        },
        pnRegistration: {
            header: 'Erlaube Benachrichtigungen',
            message: `
                In LivingApps können viele Ereignisse auftreten, beispielsweise wenn Sie eine neu
                Chatnachricht erhalten oder ein neuer Datensatz erstellt wird.
                Sie können über diese Ereignisse per Systembenachrichtigungen informiert werden,
                wenn Sie Mitteilungen in Ihrem Browser aktivieren.
                Weitere Einstellungsmöglichkeiten, wie bspw. einen Mailversand,
                 haben Sie dann später in den Einstellungen.
            `,
            warning: `
                Sie sollten Benachrichtigungen nur auf Geräten, auf die nur
                Sie Zugriff haben, erlauben, da Systembenachrichtigungen an das Gerät geschickt werden,
                auch wenn Sie gerade nicht in LivingApps eingeloggt sind.
            `
        },
        userChanged: {
            html: `
                <p>Der Nutzer der den Browser vor Ihnen nutzte hat Benachrichtigungen aktiviert.</p>
                <p>
                    Falls Sie in Ihren Mitteilungseinstellen angegeben haben, dass Sie Benachrichtigungen erhalten wollen,
                    diese aber nicht in diesem Browser erhalten wollen. So müssen Sie es deaktivieren.
                </p>
                <p>Hier sind die Anleitungen für die gängigen Browser.</p>
                <ul>
                    <li>
                        <a target="_blank" href="https://support.google.com/chrome/answer/3220216?hl=de&co=GENIE.Platform%3DDesktop">Google Chrome</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://support.apple.com/de-de/guide/safari/sfri40734/mac">Safari (Mac)</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://support.mozilla.org/de/kb/push-benachrichtigungen-firefox">Firefox</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://support.microsoft.com/de-de/microsoft-edge/verwalten-sie-website-benachrichtigungen-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b">Microsoft Edge</a>
                    </li>
                </ul>
            `
        },
    },
    pages: {
        list: {
            header: 'Mitteilungen',
            pageDescription: 'Auf dieser Seite können Sie aktuelle Mitteilungen sehen die Sie entweder per Mail oder per Benachrichtigung erhalten haben.',
            pageDescriptionFilter: 'Sie können auf diese Mitteilungen Filter anwenden um für Sie relevante Mitteilungen schneller zu finden.'
        },
        appFilter: {
            header: 'LivingApp-Filter',
            description: 'Auf dieser Seite können Sie auswählen für welche LivingApps Sie Mitteilungen sehen möchten.',
            noAppsForSearch: 'Keine LivingApp gefunden für Eingabe "{input}"',
            noApps: {
                header: 'Keine LivingApp verfügbar',
                description: 'Sie benötigen mindestens eine LivingApp um diesen Dienst nutzen zu können.'
            }
        },
        typeFilter: {
            header: 'Ereignis-Filter',
            description: 'Auf dieser Seite können Sie wählen für welche Ereignisse Sie Mitteilungen sehen möchten.',
        },
        summary: {
            header: 'Filter',
        },
        summaryDetail: {
            header: 'Mitteilungen'
        },
        userSettings: {
            header: 'Nutzer Einstellungen',
            description_1: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita ka',
            description_2: 'Die hier festgelegten Einstellungen gelten für alle Ihre LivingApps deren LivingApp Mitteilungseinstellungen die Nutzereinstellungen übernehmen. Übernehme Nutzereinstellungen ist die Standardeinstellung.',
            description_3: 'Die hier festgelegten Einstellungen gelten nur für Sie. Sie können keine Einstellungen für andere Nutzer vornehmen.',
        },
        appSettings: {
            headerLoadingStateNotOk: 'App Einstellungen',
            headerLoadingStateOk: '"{appName}" Einstellungen',
            description: 'Auf dieser Seite können Sie einstellen über welche Ereignisse in der LivingApp "{appName}" Sie per Email oder Benachrichtigung informiert werden wollen. Um Systembenachrichtigungen zu aktivieren/deaktivieren klicken Sie auf die {linkPn}',
        },
        appSettingsNav: {
            header: 'Wählen Sie eine LivingApp',
            description: 'Wählen Sie die LivingApp aus deren Einstellungen Sie ändern wollen.',
            searchNoAppFound: 'Keine LivingApp gefunden für die Eingabe "{input}"',
            noAppAvailable: {
                header: 'Keine App verfügbar',
                description: 'Sie benötigen mindestens eine LivingApp um Mitteilungen nutzen zu können.'
            }
        },
        mailIntervalSelector: {
            header: 'E-Mail Einstellungen',
            description_1: 'Um Spam-Probleme zu vermeiden, werden Benachrichtigungen per Mail zusammengefasst. ',
            description_2: 'Es kann entweder ausgewählt werden, zu welchen Zeitpunkten oder in welchen Intervallen E-Mails versandt werden.',
            infoAllSettings: 'Diese Auswahl gilt für alle LivingApps gleichermaßen. Eine individuelle Einstellung pro LivingApp ist nicht vorgesehen.',
            select: {
                label: 'Erhalten Sie maximal eine Email ...',
                '15min': 'alle 15 Minuten',
                '30min': 'alle 30 Minuten',
                'hour': 'pro Stunde',
                '2hours': 'alle 2 Stunden',
                '6hours': 'alle 6 Stunden',
                '12hours': 'alle 12 Stunden',
                'day': 'pro Tag',
                '3days': 'alle 3 Tage',
                'week': 'pro Woche'
            }
        },
        summaryHelp: {
            header: 'FAQ',
            message: `
                <h3>Wofür gibt es die Zusammenfassungen?</h3>
                <p>
                    Die Zusammenfassungen sollen Ihnen bei erhöhtem Ereignisaufkommen helfen. Auch
                    die Ereignisse wahrzunehmen, die nicht so häufig aufgetreten sind.
                </p>
                <h3>Wie werden die Mitteilungen gruppiert?</h3>
                <p>
                    Es werden alle Mitteilungen für jede LivingApp nach Datum und Ereignistyp gruppiert.
                </p>
                <h3>Weitere Fragen?</h3>
                <p>Falls Sie weitere Fragen haben, können Sie diese ganz leicht über das <a href="/feedback.htm">Feedback Formular</a> stellen.</p>
            `
        },
        settingsHelp: {
            header: 'Einstellungen FAQ',
            // v-html, supports html, do not include user content
            message: `
                <h3>Was ist der Unterschied zischen Nutzereinstellungen und LivingApp Einstellungen?</h3>
                <p>
                    Jede Mitteilung wird einer LivingApp zugeordnet. Die LivingApp Einstellungen konfigurieren
                    dann die Mitteilungen für eine gegebene LivingApp.
                </p>
                <p>
                    Damit nicht für jede LivingApp eine Einstellung vorgenommen werden muss, gibt
                    es die Nutzer Einstellungen, welche von LivingApp Einstellungen standardmäßig
                    übernommen werden.
                </p>

                <h3>Wieso gibt es Mail Einstellungen?</h3>
                <p>
                    Es ist nicht praktikabel Ihnen für jede Mitteilung eine E-Mail zuzuschicken, weil die Spam
                    Filter dann diese E-Mails herausfiltern werden. Deshalb werden E-Mails entweder
                    zu festgelegten Zeiten oder in einem festgelegtem zeitlichen Mindestabstand versendet.
                </p>
                <p>
                    Die Mail Einstellungen gelten aus oberen Grund für den Nutzer. Es gibt keine Möglichkeit
                    die Einstellung für einzelne LivingApps vorzunehmen.
                </p>

                <h3>Weitere Fragen?</h3>
                <p>Falls Sie weitere Fragen haben können Sie diese ganz leicht über das <a href="/feedback.htm">Feedback Formular</a> stellen.</p>
            `
        },
        pushNotificationSettings: {
            header: 'Systembenachrichtigungen Einstellungen',
            browserDescriptions: {
                /** v-html */
                enableHtml: `
                    <h3>Benachrichtigungen aktivieren</h3>

                    <p>Benachrichtigungen sind aktuell im Browser deaktiviert.</p>
                    <p>Sie können Benachrichtigungen in den Browsereinstellungen wieder aktivieren.</p>
                    <p>Hier sind die Anleitungen für die gängigen Browser.</p>
                    <ul>
                        <li>
                            <a target="_blank" href="https://support.google.com/chrome/answer/3220216?hl=de&co=GENIE.Platform%3DDesktop">Google Chrome</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://support.apple.com/de-de/guide/safari/sfri40734/mac">Safari (Mac)</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://support.mozilla.org/de/kb/push-benachrichtigungen-firefox">Firefox</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://support.microsoft.com/de-de/microsoft-edge/verwalten-sie-website-benachrichtigungen-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b">Microsoft Edge</a>
                        </li>
                    </ul>
                `,
                /** v-html */
                disableHtml: `
                    <h3>Benachrichtigungen deaktivieren</h3>
                    <p>Aktuell sind Benachrichtigungen aktiv.</p>
                    <p>Sie können Benachrichtigungen in den Browsereinstellungen deaktivieren.</p>
                    <p>Hier sind die Anleitungen für die gängigen Browser.</p>
                    <ul>
                        <li>
                            <a target="_blank" href="https://support.google.com/chrome/answer/3220216?hl=de&co=GENIE.Platform%3DDesktop">Google Chrome</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://support.apple.com/de-de/guide/safari/sfri40734/mac">Safari (Mac)</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://support.mozilla.org/de/kb/push-benachrichtigungen-firefox">Firefox</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://support.microsoft.com/de-de/microsoft-edge/verwalten-sie-website-benachrichtigungen-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b">Microsoft Edge</a>
                        </li>
                    </ul>
                `,
            },
            clearBlockingPersistentTokens: {
                header: 'Benachrichtigungen wieder aktivieren',
                message: 'Aktuell sind Benachrichtigungen deaktiviert. Sie können die Benachrichtigungen hier wieder aktivieren.',
                button: 'Benachrichtigungen aktivieren'
            }
        }
    },
    components: {
        filtericonBar: {
            header: 'Filter',
            description: 'Zeige Mitteilungen an für',
            descriptionSummary: 'Zeige Zusammenfassungen an für',
        },
        noNotificationsView: {
            header: 'Keine Mitteilungen gefunden',
            notificationsAvailableWithoutFilters_1:
                'Wir haben keine Mitteilungen für Ihre Filterkonfiguration gefunden.',
            notificationsAvailableWithoutFilters_2:
                'Wenn Sie die Filter deaktivieren stehen Ihnen bis zu {n} Mitteilungen bereit.',
            noNotificationsAvailable: 'Es sind derzeit keine Mitteilungen für Sie verfügbar.',
            directUserToSettings: 'Falls Ihnen in Zukunft mehr Mitteilungen angezeigt werden sollen, passen Sie Ihre {settings} an.',
        },
        noSummariesView: {
            header: 'Keine Zusammenfassungen gefunden',
            withActiveFilters: 'Setzen Sie die Filter zurück um wieder Zusammenfassungen zu sehen.',
            noSummaries: 'Es sind derzeit keine Mitteilungen für Sie verfügbar. Falls Ihnen in Zukunft mehr Mitteilungen angezeigt werden sollen, passen Sie Ihre {settings} an.'
        },
        filtersNotValid: {
            header: 'Filter nicht gültig',
            noAppSelected: 'Wählen Sie mindestens eine LivingApp aus um den LivingApps Filter anzuwenden.',
            noTypeSelected: 'Wählen Sie mindestens ein Ereignis aus um den Ereignis Filter anzuwenden.',
            invalidTimespan: 'Der von Ihnen angegebene Zeitrahmen ist ungültig',
        },
        error: {
            header: 'Es ist ein Fehler aufgetreten',
            description: `
                Bitte laden Sie die Seite neu. Falls der Fehler weiterhin besteht wenden Sie
                sich bitte an den Support.
            `
        },
        dateSpanFilter: {
            description: `Aktuell werden Mitteilungen von {from} bis {to} angezeigt.`
        },
        appSetting: {
            inherit: 'Nutzer Einstellungen übernehmen (grau markiert)',
            showInCenter: 'Auf LivingApps Platform anzeigen',
            sendMail: 'Per E-Mail zuschicken',
            sendPushNotification: 'Per Systembenachrichtigung zuschicken'
        },
        userSetting: {
            mailDescription: 'Sie wollen E-Mails erhalten. Sie können in den {mailSettings} kontrollieren wieviele E-Mails Sie erhalten wollen.',
            overwrittenAppsExists: 'Für dieses Ereignis wurde in {count} LivingApps explizit eine Einstellung vorgenommen.',
            overwrittenAppSettings: {
                editing: 'Sie können die Einstellung für diese App {link} bearbeiten.',
                inherit: 'Übernimmt die Nutzereinstellungen.',
                link: 'hier',
            }
        },
        notificationsSummary: {
            lastSeen: 'Zuletzt gesehen um',
            numberOfNotifications: 'Anzahl der Mitteilungen:'
        },
        searchbar: 'Suchbegriff',
        dataProtectionPn: {
            notSetText: `
                Um per Benachrichtung über Ereignisse informiert zu werden müssen Sie zustimmen,
                dass Google und Apple Daten verarbeiten darf. Diese Daten können auch außerhalb
                von Europa verarbeitet werden.

                Als Inhalt der Benachrichtungen werden keine personenbezogene Daten an Apple und Google geschickt.

                Mehr dazu {link}`,
            linkToPage: 'hier',
            denyButton: 'Deaktiviere Benachrichtungen',
            allowButton: 'Aktiviere Benachrichtigungen',

            deniedText: `
                Benachrichtungen sind deaktiviert. Sie können Ihre Einstellunge {link} ändern.
            `,
            allowedText: 'Sie haben Benachrichtungen aktiviert. Sie können diese {link} hier deaktivieren und die Datenschutzbestimmungen nachlesen.'
        },
        details: {
            less: 'weniger anzeigen',
            more: 'mehr anzeigen',
        },
        privacyHint: {
            header: 'Datenschutz Hinweis',
            // inserted into v-html, do not insert any user content
            message: `
                <p>
                    Für diesen Service werden Dienste von Apple und Google genutzt.
                </p>
                <p>
                Diese erheben Identifier von Ihnen um Ihnen die Benachrichtungen zustellen zu können.
                </p>
                <p>
                    Die Inhalte der Benachrichtigungen werden in verschlüsselter Form übertragen und können nicht
                    von Apple oder Google ausgelesen werden.
                </p>
                <p>
                    Mehr Informationen erhalten Sie in der <a href="/datenschutz.htm">Datenschutzerklärung</a>.
                </p>
                <p>
                    Wenn Sie die Funktion nicht aktivieren werden keine Personenbezogenen Daten von Ihnen erhoben.
                <p>
            `,
        },
        allowPn: {
            // v-html, do not insert any user content
            message: `
               <p>Systembenachrichtigungen informieren in Echtzeit über Chats und Ereignisse, auch wenn LivingApps nicht aktiv ist. </p>
            `,
            button: 'Systembenachrichtigungen aktivieren'
        },
        notification: {
            submenu: {
                markAsRead: 'als gelesen markieren',
                ignoreAll: 'ignoriere "{ev}" Ereignisse',
                ignoreAllForApp: 'ignoriere "{ev}" Ereignisse in App "{app}"',
                openAppSettings: 'öffne "{app}" Einstellungen'
            }
        }
    },
    buttons: {
        resetAppFilter: 'Setze LivingApp Filter zurück',
        resetDateFilter: 'Setze Zeitrahmen zurück',
        resetTypeFilter: 'Setze Ereignis Filter zurück',
        resetSearch: 'Suche zurücksetzen',
        reloadPage: 'Seite neu laden',
        loadMoreNotifications: 'Mehr Mitteilungen laden',
        selectAll: 'Alle wählen',
        deselectAll: 'Alle abwählen',
        applyFilter: 'Filter anwenden',
        appFilter: 'Keine LivingApp | 1 LivingApp | {n} LivingApps',
        typeFilter: 'Kein Ereignis | 1 Ereignis | {n} Ereignisse',
        dateFilter: 'von {from} bis {to}',
        allAppsSelected: 'Alle LivingApps',
        allTypesSelected: 'Alle Ereignisse',
        showOverwrittenApps: 'Apps einblenden',
        hideOverwrittenApps: 'Apps ausblenden,',
        openInTab: 'In neuen Tab öffnen',
        pnRegisterOk: 'Erlaube Benachrichtigungen',
        pnRegisterCancel: 'Abbrechen',
    },
    nav: {
        settings: 'Einstellungen',
        summary: 'Filter',
        help: 'FAQ',
        appSettings: 'LivingApp Einstellungen',
        mailSettings: 'Mail Einstellungen',
        pnSettings: 'Systembenachrichtigung Einstellung',
        privacyHtm: 'Datenschutzerklärung',
    },
};