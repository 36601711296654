<template>
<div class="lan-notification-summary">
    <div class="lan-icon" :style="{ backgroundImage: `url('${imgProxy(props.icon, { h: 64, w: 64 }, true)}')`}"></div>
    <div class="lan-content">
        <h3 v-if="props.appName && props.appId && props.message.de.indexOf('\{\{appName\}\}') >= 0" class="lan-title">
            {{ props.message[locale].split('\{\{appName\}\}')[0] ?? '' }}
            <a v-if="!isMobile" @click="ev => ev.stopPropagation()" :href="`/apps/${props.appId}.htm`">
                {{ props.appName }}
            </a>
            <span v-else>{{ props.appName }}</span>
            {{ props.message[locale].split('\{\{appName\}\}')[1] ?? '' }}
        </h3>
        <h3 v-else class="lan-title">{{ props.message[locale].replace(/\{\{appName\}\}/g, '') }}</h3>
        <p class="lan-message">letztes Ereignis:&nbsp;{{ formatTime(props.lastSeen) }}, Anzahl Ereignisse:&nbsp;{{ props.count }}</p>
    </div>
    <i class="fa-light fa-chevron-right lan-arrow-icon"></i>
</div>
</template>

<script setup lang="ts">
import { formatTime } from '@/utils/dateformatter';
import { imgProxy } from '@/utils/imgproxy';
import { useI18n } from 'vue-i18n';
import { TI18N } from '@/models/common';
import { computed } from 'vue';
import { SupportedLocale } from '@/i18n';
import { deGlobal } from '@/i18n/de';
import { enGlobal } from '@/i18n/en';

const props = defineProps<{
    message: TI18N;
    icon: string;
    lastSeen: number;
    count: number;
    appId?: string;
    appName?: string;
    isMobile: boolean;
}>();

const { locale: tmpLocale } = useI18n({
    messages: { de: deGlobal, en: enGlobal }
});
const locale = computed(() => (tmpLocale.value as string).substring(0, 2) as SupportedLocale);
</script>
<style scoped>
.lan-notification-summary {
    display: flex;
    gap: 10px;
    cursor: pointer;
    position: relative;
    padding: 10px 20px;
    transition: background .3s ease-out;
}

.lan-notification-summary:hover {
    background-color: rgb(235, 238, 240);
}

.lan-icon {
    width: 64px;
    height: 64px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.lan-content {
    flex: 1;
}

.lan-title {
    margin: 0 40px 0 0;
    font-size: 1.25rem;
}

.lan-message {
    margin: 0;
}

.lan-arrow-icon {
    margin-right: 10px;
    font-size: 20px;
    align-self: center;
}
</style>