import { createMemoryHistory, createRouter } from "vue-router";
// import UserSettingsPage from '@/pages/UserSettingsPage.vue';
import AppSettingsPage from "@/pages/browsershell/AppSettingsPage.vue";
import SettingsHelpPage from '@/pages/SettingsHelpPage.vue';
import FilterPage from "@/pages/browsershell/FilterPage.vue";
import FilterDetailPage from "@/pages/browsershell/FilterDetails.vue";
import FilterHelpPage from "@/pages/FilterHelpPage.vue";
import SettingsPageVue from "@/pages/browsershell/SettingsPage.vue";

export const router = createRouter({
    history: createMemoryHistory(),
    routes: [
        // settings app
        { path: '/settings', component: SettingsPageVue },
        { path: '/settings/app/:appId', component: AppSettingsPage, props: true },
        { path: '/settings/help', component: SettingsHelpPage },

        // filter app
        { path: '/filter', component: FilterPage },
        {
            path: '/filter/help',
            component: FilterHelpPage,
        },
        {
            path: '/filter/detail/:after/:before/:type/:appId',
            component: FilterDetailPage,
            props: true,
        },
    ],
});