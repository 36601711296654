<template>
<div :class="pageStyles.page">
    <div :class="pageStyles.bar">
        <BackButton v-if="showBack"/>
        <h3></h3>
        <div :class="navigationStyles.lgScreen">
            <RouterLink to="/settings/help">
                <i class="fa-light fa-question"></i> {{ t('nav.help') }}
            </RouterLink>
        </div>
        <CloseModalButton />
    </div>
    <div :class="pageStyles.content + ' nav-content'">
        <div :class="navigationStyles.smScreen">
            <LaButton
                size="expand"
                color="primary"
                :class="navigationStyles.buttonWithIcon"
                @click="router.push('/settings/help')"
            >
                <i class="fa-light fa-question"></i> {{ t('nav.help') }}
            </LaButton>
        </div>
    </div>
    <div class="nav-spacer"></div>
    <AppSettingsTab :app-id="props.appId"/>
</div>
</template>
<script setup lang="ts">
import BackButton from '@/components/BackButton.vue';
import CloseModalButton from '@/components/CloseModalButton.vue';
import { useI18n } from 'vue-i18n';

import pageStyles from '@/styles/page.module.css';
import navigationStyles from '@/styles/navigation.module.css';
import { useRouter } from 'vue-router';
import { deGlobal } from '@/i18n/de';
import { enGlobal } from '@/i18n/en';
import AppSettingsTab from '../content/AppSettings.vue';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';

const props = defineProps<{ appId: string }>();

const { t } = useI18n({
    messages: {
        en: enGlobal,
        de: deGlobal
    }
});
const router = useRouter();


const showBack = router.currentRoute.value.query.showBack === 'true';

</script>
<style scoped>
.icon {
    align-self: center;
    width: 45px;
    height: 45px;
    border: 2px solid #dedede;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.nav-content {
    display: block;
    margin-bottom: 0;
}

.nav-spacer {
    display: none;
}

@media screen and (min-width: 768px) {
    .nav-content {
        display: none;
    } 
    
    .nav-spacer {
        display: block;
        width: 100%;
        height: 20px;
    }
}
</style>