export function formatDate(epochMilli: number, displayYear: boolean = true) {
    const date = new Date(epochMilli);
    if (displayYear) {
        return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth()+1).padStart(2, '0')}.${date.getFullYear()}`;
    }
    else {
        return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth()+1).padStart(2, '0')}`
    }
}

export function formatDateForDateInput(epochMilli: number) {
    const date = new Date(epochMilli);
    // return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth()+1).padStart(2, '0')}.${date.getFullYear()}`;
    return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

export function formatTime(epochMilli: number) {
    const date = new Date(epochMilli);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}