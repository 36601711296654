<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { fetchAppList, fetchEventMetadata, fetchNotificationLatest, setNotificationsAsRead, useFetch } from '@/utils/fetchplus';
import { getNotificationCount } from '@/utils/fetchplus';
import { useAppStore } from '@/store';
import Badge from '@/components/Badge.vue';
import Popper from 'vue3-popper';
import LaModal from '@ll/form-utils/src/components/LaModal.vue';
import { globalEventBus } from '@/eventbus';
import './style.css';
import { router } from './router';
import { TResGetSettingsUserPush } from '@/models/responses';
import { useEventListener } from '@vueuse/core';
import List from '@/components/List.vue';
import { useI18n } from 'vue-i18n';
import AllowPn from '@/components/AllowPn.vue';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';

const body = document.body;

const { state: unreadNotificationsCountState, executeFetch: executeFetchUnreadNotificationsCount } = useFetch(getNotificationCount);
const { state: notificationsState, executeFetch: executeFetchNotifications } = useFetch(fetchNotificationLatest);
const { state: appDataState, executeFetch: executeFetchAppData } = useFetch(fetchAppList);
const { state: eventDataState, executeFetch: executeFetchEventData } = useFetch(fetchEventMetadata);
const appStore = useAppStore();
const { t } = useI18n();
const counter = ref(0);
const shake = ref(false);
const modalMountElement = ref<HTMLDivElement | null>(null);
const isPushAllowed = ref(false);

const bellClasses = computed(() => ({
    'fa-regular': true,
    'fa-bell': true,
    'lan-notifi-bell': true,
    'lan-notifi-show-counter': counter.value > 0,
    'fa-shake': shake.value,
}));

const bellContainerClass = computed(() => ({
    'lan-badge-bell-container': true,
    'lan-counter-offset': counter.value > 0,
}));


const appData = computed(() => {
    if (appDataState.value.state === 'ok') {
        return Object.fromEntries(appDataState.value.data.map((d) => [d.appId, d]))
    }
    return undefined;
});
const eventData = computed(() => {
    if (eventDataState.value.state === 'ok') {
        return eventDataState.value.data;
    }
    return undefined;
});

onMounted(async () => {
    await updateUnreadNotificationCount();
    executeFetchAppData();
    const response = await fetch('/notifications_backend/settings/user/push');
    if (response.status !== 200) {
        return;
    }
    const body = (await response.json()) as TResGetSettingsUserPush;
    if (body.hasPushAllowed) {
        isPushAllowed.value = true;
    }
});

// check if the popup should be closed if the user clicks away
useEventListener(document, 'click', (ev) => {
    if (!appStore.isPopperOpen) {
        return;
    }
    for (const el of ev.composedPath()) {
        if ((el as HTMLElement).classList?.contains('lan-popup-frame') || (el as HTMLElement).classList?.contains('lan-badge-bell-container')) {
            return;
        }
    }
    appStore.isPopperOpen = !appStore.isPopperOpen;
});

globalEventBus.on('setNotificationsAsRead', () => {
    console.info('[Global Bell] update Badge counter');
    updateUnreadNotificationCount();
});

async function updateUnreadNotificationCount() {
    await executeFetchUnreadNotificationsCount(undefined, undefined, undefined, undefined, true);
    if (unreadNotificationsCountState.value.state === 'ok') {
        counter.value = unreadNotificationsCountState.value.data.count;
    }
}

function handleBellClick() {
    counter.value = 0;
    if (!appStore.isPopperOpen) {
        executeFetchNotifications(
            undefined,
            undefined,
            undefined,
            undefined,
            100,
            undefined
        )
            .then(() => {
                return setNotificationsAsRead();
            })
            .then(() => {
                globalEventBus.emit('setNotificationsAsRead', new Date());
            });
    }
    appStore.isPopperOpen = !appStore.isPopperOpen;
}

function updateNotifications() {
    executeFetchNotifications(
        undefined,
        undefined,
        undefined,
        undefined,
        100,
        undefined
    )
        .then(() => {
            return setNotificationsAsRead();
        })
        .then(() => {
            globalEventBus.emit('setNotificationsAsRead', new Date());
        });
}

function openModalPage(url: string) {
    appStore.isPopperOpen = false;
    appStore.isModalOpen = true;
    router.replace(url);
}

function handleModalDismiss() {
    appStore.isModalOpen = false;
    sessionStorage.removeItem('searchbarInput-AppFilterPage');
    sessionStorage.removeItem('searchbarInput-AppSettingsNavPage');
}

function handleOpenMoreNotification() {
    executeFetchEventData();
}

function allowedPn() {
    isPushAllowed.value = true;
    appStore.isPopperOpen = false;
}
</script>
<template>
<!-- Bell Button -->
<Popper
    class="lan-bell-overlay"
    placement="bottom-end"
    offset-skid="100px"
    :show="notificationsState.state === 'ok' && appStore.isPopperOpen"
>
    <div
        :class="bellContainerClass"
        @click="handleBellClick"
    >
        <Badge v-if="counter > 0"
            class="lan-counter"
        >
            {{ counter > 9 ? '9+' : counter }}
        </Badge>
        <i
            :class="bellClasses"
            data-source="Notifications Api Global Bell App Teleport"
        ></i>
    </div>
    <template #content>
        <template v-if="notificationsState.state === 'ok'">
            <div class="lan-popup-frame">
                <LaButton v-if="counter > 0" @click="updateNotifications" size="large" class="lan-reload" color="primary">
                    <i class="fa-light fa-rotate-right"></i> Mitteilungen aktualisieren
                </LaButton>
                <!-- display links to summaries and settings -->
                <div class="lan-popup-content">
                    <h3>
                        {{ t('apps.bells.header') }}
                    </h3>
                    <div class="lan-flex">
                        <div @click="openModalPage('/filter')">
                            <a class="lan-link">
                                <i class="fa-light fa-filter"></i>
                                {{ t('nav.summary') }}
                            </a>
                        </div>
                        <div @click="openModalPage('/settings')">
                            <a class="lan-link">
                                <i class="fa-light fa-gear"></i>
                                {{ t('nav.settings') }}
                            </a>
                        </div>
                    </div>
                </div>
                <AllowPn v-if="!isPushAllowed" @allowed="allowedPn"/>
                <!-- display the current notifications grouped by date -->
                <template v-if="appDataState.state === 'ok'">
                    <template v-if="notificationsState.data.length > 0">
                        <List
                            :is-mobile="false"
                            :app-data="appData"
                            :event-data="eventData"
                            :is-displayed-in-modal="false"
                            :data="notificationsState.data"
                            notification-class="lan-notification-padding"
                            @open-more="handleOpenMoreNotification"
                        />
                    </template>
                    <tempalte v-else>
                        <h3 class="lan-no-notifications">{{ t('apps.bells.noNotifications') }}</h3>
                    </tempalte>
                </template>
                </div>
        </template>
    </template>
</Popper>

<!-- Modal Mount target -->
<Teleport :to="body">
    <div ref="modalMountElement" data-source="Notifications Api Global Bell App Teleport"></div>
</Teleport>
<!-- Modal -->
<LaModal v-if="modalMountElement !== null"
    :is-open="appStore.isModalOpen"
    :mount-element="modalMountElement"
    @dismiss="handleModalDismiss"
>
    <div class="lan-modal-page lan-animate-timing">
        <RouterView v-slot="{ Component, route }">
            <Transition
                :enter-active-class="(route.meta.enterClass as string)"
                :leave-active-class="(route.meta.leaveClass as string)"
            >
                <KeepAlive>
                    <component :is="Component" />
                </KeepAlive>
            </Transition>
        </RouterView>
    </div>
</LaModal>
</template>
<style scoped>
.lan-bell-overlay {
    box-sizing: content-box;
    --popper-theme-background-color: rgb(255, 255, 255);
    --popper-theme-background-color-hover: rgb(255, 255, 255);
    --popper-theme-text-color: black;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 4px;
    --popper-theme-padding: 0;
    --popper-theme-box-shadow: 0 1px 6px rgba(0,0,0,.25);
}

.lan-popup-frame {
    width: 100vw;
    max-height: 80dvh;
    max-height: 80vh;
    background-color: white;
}

.lan-popup-content {
    padding: 15px 10px;
}

@media screen and (min-width: 550px) {
    .lan-popup-frame {
        width: 450px;
    }

}

.lan-flex {
    display: flex;
    justify-content: space-between;
}

.lan-no-notifications {
    padding: 15px 10px;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    color: #8a8d99;
}

.lan-list-date {
    margin: 15px auto;
    width: 150px;
    text-align: center;
    background-color: #ddd;
    color: #333;
    border-radius: 12px;
    font-weight: 600;
    font-size: 1rem;
    border: 1px solid #bbb;
    line-height: 1.2rem;
}

.lan-counter {
    position: absolute;
    left: 0;
    margin-left: 27px;
    margin-top: -5px;
}

.lan-reload {
    position: fixed;
    margin-left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    text-align: center;
    top: 20px;
    max-width: 350px;
    white-space: nowrap;
}
</style>