<script setup lang="ts">
import LaConfirm from '@ll/form-utils/src/components/LaConfirm.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import DataprotectionHint from '@/components/DataprotectionHint.vue';

const props = defineProps<{
    resolveFn: Function;
}>();

const { t } = useI18n();

const buttons = computed(() => [
    {
        label: 'Ok',
        key: 'ok',
        clear: false,
        size: 'large',
        color: 'primary',
        value: false
    }
] as any);
</script>

<template>
<LaConfirm
    :open="true"
    header="Benachrichtigungen behalten"
    :buttons="buttons"
    :dismissValue="undefined"
    @resolve-value="v => props.resolveFn(v)"
>
    <div v-html="t('apps.userChanged.html')">
    </div>
    <DataprotectionHint />
</LaConfirm>
</template>