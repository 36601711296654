<template>
<div :class="pageStyles.page">
    <div :class="pageStyles.bar">
        <h3></h3>
        <div :class="navigationStyles.lgScreen">
            <RouterLink to="/settings/help">
                <i class="fa-light fa-question"></i> {{ t('nav.help') }}
            </RouterLink>
        </div>
        <CloseModalButton />
    </div>
    <div :class="`${navigationStyles.smScreen} ${pageStyles.content}`" style="padding-bottom: 0">
        <LaButton
            size="expand"
            color="primary"
            :class="navigationStyles.buttonWithIcon"
            @click="router.push('/settings/help')"
        >
            <i class="fa-light fa-question"></i> {{ t('nav.help') }}
        </LaButton>
    </div>
    <div class="tabs" ref="tabEl">
        <div>
            <nav style="padding: 0 20px">
                <ul>
                    <li v-if="!props.appId">
                        <a
                            href="#lan-user"
                            target="_top"
                            ref="tab_interval"
                            class="tab"
                        >
                            Nutzer
                        </a>
                    </li>
                    <li>
                        <a
                            href="#lan-apps"
                            target="_top"
                            ref="tab_interval"
                            class="tab"
                        >
                            LivingApp
                        </a>
                    </li>
                    <li>
                        <a
                            href="#lan-systembenachrichtigung"
                            target="_top"
                            ref="tab_interval"
                            class="tab"
                        >
                            Systembenachrichtigungen
                        </a>
                    </li>
                    <li>
                        <a
                            href="#lan-mail"
                            target="_top"
                            ref="tab_interval"
                            class="tab"
                        >
                            E-Mail
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="content">
            <section id="lan-user" v-if="!props.appId">
                <UserSettingsTab :is-mobile="false"/>
            </section>
            <section v-if="props.appId" id="lan-apps">
                <AppSettingsTab :app-id="props.appId" />
            </section>
            <section v-else id="lan-apps">
                <AppSettingsNavTab />
            </section>
            <section id="lan-systembenachrichtigung">
                <PNSettingsTab />
            </section>
            <section id="lan-mail">
                <MailSettingsTab />
            </section>
        </div>
    </div>
</div>
</template>
<script setup lang="ts">
import pageStyles from '@/styles/page.module.css';
import navigationStyles from '@/styles/navigation.module.css';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import LaButton from '@ll/form-utils/src/components/LaButton.vue';
import CloseModalButton from '@/components/CloseModalButton.vue';
import { onMounted, ref } from 'vue';
import UserSettingsTab from '@/pages/content/UserSettings.vue';
import MailSettingsTab from '@/pages/content/MailSettings.vue';
import PNSettingsTab from '../SettingsTabs/PNSettingsTab.vue';
import AppSettingsNavTab from '../SettingsTabs/AppSettingsNavTab.vue';
import AppSettingsTab from '../content/AppSettings.vue';

const props = defineProps<{
    appId?: string;
}>();

const { t } = useI18n();
const router = useRouter();

const tabEl = ref<HTMLDivElement | undefined>();

onMounted(() => {
    ///@ts-expect-error
    new CBPFWTabs(tabEl.value);

});
</script>

<style scoped>
.content {
    margin: 0;
} 
.loading {
    max-height: 16px;
    max-width: 16px;
}

.time-container {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid grey;
    margin: 0.625rem 0 0 0;
}

.time-container:last-of-type {
    border-bottom: none;
}



.time-input-container {
    padding-right: 20px;
}

.day-container {
    flex: 1;
    flex-shrink: 200px;
}

.delete-container {
    width: fit-content;
}

.button-container {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}
.tab {
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>